import React from "react";
import { CogIcon, UserCircleIcon, CashIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Image, Dropdown } from 'react-bootstrap';

import AccountImage from "assets/img/account.png";
import { Routes } from "routes";
import { Link } from 'react-router-dom';

import getBackend from "backend/backend";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: null};
        this.signout = this.signout.bind(this);
    }

    async componentDidMount() {
        const result = await getBackend().account();
        const email = (result.error === null) ? result.response.email : null;

        this.setState(state => ({
            email: email
        }));
    }

    async signout(e) {
        e.preventDefault();

        ReactGA.event({
            category: "user",
            action: "signout",
          });
        const result = await getBackend().signout();
        if (result.error == null) {
            this.setState(state => ({
                email: null
            }));
            window.location.href = '/';
        }
    }

    render() {
        return (
            <Dropdown as={Nav.Item} className="ms-lg-3">
            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                    <Image src={AccountImage} className="avatar rounded-circle" />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
            
            {this.state.email == null &&
                <>
                <Dropdown.Item className="d-flex align-items-center" as={Link} to={Routes.Signin.path}>
                    <UserCircleIcon className="dropdown-icon text-gray-400 me-2" /> {this.props.t('Sign in')}
                </Dropdown.Item>

                <Dropdown.Item className="d-flex align-items-center" as={Link} to={Routes.Signup.path}>
                    <UserCircleIcon className="dropdown-icon text-gray-400 me-2" /> {this.props.t('Create account')}
                </Dropdown.Item>
                </>
            }

            {this.state.email != null &&
                <>
                <Dropdown.Item className="d-flex align-items-center">
                    <UserCircleIcon className="dropdown-icon text-gray-400 me-2" /> {this.state.email}
                </Dropdown.Item>

                <Dropdown.Item className="d-flex align-items-center" as={Link} to={Routes.Settings.path}>
                    <CogIcon className="dropdown-icon text-gray-400 me-2"/> {this.props.t('Settings')}
                </Dropdown.Item>

                <Dropdown.Item className="d-flex align-items-center" as={Link} to={Routes.Billing.path}>
                    <CashIcon className="dropdown-icon text-gray-400 me-2" /> {this.props.t('Billing')}
                </Dropdown.Item>

                <Dropdown.Divider as="div" className="my-1" />

                <Dropdown.Item className="d-flex align-items-center" onClick={this.signout}>
                    <LogoutIcon className="dropdown-icon text-danger me-2" /> {this.props.t('Logout')}
                </Dropdown.Item>
                </>
            }
            </Dropdown.Menu>
        </Dropdown>
  
        )
    }
}

export default (props) => {
    const [t, i18n] = useTranslation('common');

    return (
        <Account t={t}/>
    );
};