
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, ArchiveIcon, CheckIcon, CogIcon, PauseIcon, PlayIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import {timestampToString, capitalizeFirstLetter, SwalWithBootstrapButtons, showError} from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Image, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";
import { showMessage } from "util/util";

const ComputersTable = (props) => {
  const { computers = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = computers.filter(u => u.isSelected).length === 0;

  const selectComputer = (id) => {
    props.selectComputer && props.selectComputer(id);
  };

  const selectAllComputers = () => {
    props.selectAllComputers && props.selectAllComputers();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteComputers();
    if (bulkOption === "pause") pauseComputers();
    if (bulkOption === "resume") resumeComputers();
    if (bulkOption === "update") updateComputers();
    if (bulkOption === "backup") backupComputers();
    if (bulkOption === "reboot") rebootComputers();
  }

  const deleteComputers = (ids) => {
    props.deleteComputers && props.deleteComputers(ids)
  }

  const pauseComputers = (ids) => {
    props.pauseComputers && props.pauseComputers(ids)
  }

  const resumeComputers = (ids) => {
    props.resumeComputers && props.resumeComputers(ids)
  }

  const updateComputers = (ids) => {
    props.updateComputers && props.updateComputers(ids)
  }

  const backupComputers = (ids) => {
    props.backupComputers && props.backupComputers(ids)
  }

  const rebootComputers = (ids) => {
    props.rebootComputers && props.rebootComputers(ids)
  }

  const TableRow = (props) => {
    const { machine_id, status, hostname, agent_version, last_seen, isSelected } = props;
    const statusVariant = status === "active" ? "success"
      : status === "inactive" ? "muted"
        : status === "pending" ? "purple"
          : status === "suspended" ? "danger" : "primary";

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`computer-${machine_id}`} checked={isSelected} onChange={() => selectComputer(machine_id)} />
            <FormCheck.Label htmlFor={`computer-${machine_id}`} />
          </FormCheck>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center" as={Link} to={Routes.Host.path + "/" + machine_id}>
            <div className="d-block">
              <span className="fw-normal">{machine_id}</span>
            </div>
          </Card.Link>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center" as={Link} to={Routes.Host.path + "/" + machine_id}>
            <div className="d-block">
              <span className="fw-bold">{hostname}</span>
            </div>
          </Card.Link>
        </td>
        <td><span className="fw-normal">{timestampToString(last_seen)}</span></td>
        <td>
        <span className="fw-normal">{agent_version}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
            <Dropdown.Item className="d-flex align-items-center" onClick={() => backupComputers([machine_id])}>
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" />
                Start backup
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={() => pauseComputers([machine_id])}>
                <PauseIcon className="dropdown-icon text-gray-400 me-2" />
                Pause
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={() => resumeComputers([machine_id])}>
                <PlayIcon className="dropdown-icon text-gray-400 me-2" />
                Resume
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteComputers([machine_id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="backup">Backup</option>
            <option value="pause">Pause</option>
            <option value="resume">Resume</option>
            <option value="delete">Delete</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllComputers} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Id</th>
              <th className="border-bottom">Hostname</th>
              <th className="border-bottom">Last seen</th>
              <th className="border-bottom">Agent version</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {computers.map(u => <TableRow key={`computer-${u.machine_id}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default () => {

  const [computers, setComputers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const selectedComputersIds = computers.filter(u => u.isSelected).map(u => u.machine_id);
  const totalComputers = computers.length;
  const allSelected = selectedComputersIds.length === totalComputers;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newComputers = computers.map(u => ({ ...u, show: u.hostname.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setComputers(newComputers);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newComputers = computers.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setComputers(newComputers);
  };

  const selectAllComputers = () => {
    const newComputers = selectedComputersIds.length === totalComputers ?
    computers.map(u => ({ ...u, isSelected: false })) :
    computers.map(u => ({ ...u, isSelected: true }));

    setComputers(newComputers);
  };

  const selectComputer = (id) => {
    const newComputers = computers.map(u => u.machine_id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setComputers(newComputers);
  };

  const deleteComputers = async (ids) => {
    const computersToBeDeleted = ids ? ids : selectedComputersIds;
    const textMessage = computersToBeDeleted.length === 1
      ? "Are you sure do you want to delete this computer?"
      : `Are you sure do you want to delete these ${computersToBeDeleted.length} computers?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      for (let i = 0; i < computersToBeDeleted.length; i++) {
        await getBackend().deleteMachine(computersToBeDeleted[i]);
      }

      const newComputers = computers.filter(f => !computersToBeDeleted.includes(f.machine_id));
      const confirmMessage = computersToBeDeleted.length === 1 ? "The computer has been deleted." : "The computers have been deleted.";

      setComputers(newComputers);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const pauseComputers = async (ids) => {
    const selectedIds = ids ? ids : selectedComputersIds;

    for (let i = 0; i < selectedIds.length; i++) {
      await getBackend().pauseMachine(selectedIds[i]);
    }

    await showMessage("Agent status", "paused");
  }

  const resumeComputers = async (ids) => {
    const selectedIds = ids ? ids : selectedComputersIds;

    for (let i = 0; i < selectedIds.length; i++) {
      await getBackend().resumeMachine(selectedIds[i]);
    }
    await showMessage("Agent status", "resumed");
  }

  const backupComputers = async (ids) => {
    const selectedIds = ids ? ids : selectedComputersIds;

    for (let i = 0; i < selectedIds.length; i++) {
      const result = await getBackend().backupMachine(selectedIds[i]);
      if (result.error !== null) {
        await showError(result.error);
        return;
      }
    }

    await showMessage("Agent status", "backup started");
  }

  const rebootComputers = async (ids) => {
    const selectedIds = ids ? ids : selectedComputersIds;

    for (let i = 0; i < selectedIds.length; i++) {
      await getBackend().rebootMachine(selectedIds[i]);
    }

    await showMessage("Agent status", "rebooting");
  }

  const updateComputers = async (ids) => {
    const selectedIds = ids ? ids : selectedComputersIds;

    for (let i = 0; i < selectedIds.length; i++) {
      await getBackend().updateMachine(selectedIds[i]);
    }
  }

  const isActive = (last_seen) => {
    const now = 1000*Date.now();
    if (last_seen >= now)
      return true;

    if ((now - last_seen) > 10*60*1000*1000) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    let canceled = false;

    const getComputers = async () => {
      const result = await getBackend().machines();
      if (canceled)
        return;

      if (result.error == null) {
        setComputers(result.response.machines.map(u => ({ ...u, isSelected: false, show: true, status: isActive(u.last_seen) ? "active" : "inactive"})));
      } else {
        if (!canceled)
          await showError(result.error);
      }
    };

    getComputers();
    return () => {
      canceled = true;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Cydanix Hosts cydanix.com</title>
        <meta name="description" content="Cydanix hosts" />
      </Helmet>
      <SidebarPageHeader pageName="Hosts"/>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search hosts"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="pending">Pending</option>
              <option value="suspended">Suspended</option>
            </Form.Select>
          </Col>
        </Row>
      </div>

      <ComputersTable
        computers={computers.filter(u => u.show).sort((a, b) => {
          if (a.last_seen > b.last_seen)
            return -1;
          if (a.last_seen < b.last_seen)
            return 1;
          return 0;
        })}
        allSelected={allSelected}
        selectComputer={selectComputer}
        deleteComputers={deleteComputers}
        backupComputers={backupComputers}
        pauseComputers={pauseComputers}
        resumeComputers={resumeComputers}
        updateComputers={updateComputers}
        rebootComputers={rebootComputers}
        selectAllComputers={selectAllComputers}
      />
    </>
  );
};
