
import React from "react";
import getBackend from "backend/backend";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

class SubscribeForm extends React.Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
      this.onEmailChange = this.onEmailChange.bind(this);
      this.state = { error: null , email: null};
  }

  async onSubmit(e) {
      e.preventDefault();

      ReactGA.event({
        category: "user",
        action: "subscribe",
      });

      this.setState(state => ({
          error: null
      }), async () => {
          const result = await getBackend().subscribe(this.state.email);
          this.setState(state => ({
              error: result.error
          }));
      });
  }

  onEmailChange(e) {
      this.setState(state => ({
          email: e.target.value
      }));
  }

  render() {
    return (
        <div>
            <span className="h5 mb-3 d-block">{this.props.t('Subscribe to receive news')}</span>
            <form onSubmit={this.onSubmit}>
            <div className="form-row mb-2">
                <div className="col-12">
                <input type="email" className="form-control mb-2" placeholder="Your email address" name="email" aria-label="Subscribe form" onChange={this.onEmailChange} required />
                </div>
                <div className="col-12 d-grid gap-2">
                <button type="submit" className="btn btn-secondary text-dark shadow-soft" data-loading-text="Sending">
                    <span>{this.props.t('Subscribe')}</span>
                </button>
                </div>
            </div>
            </form>
            <p className="text-muted font-small m-0">{this.props.t('We’ll never share your details')}</p>
        </div>
      );
  }
}


export default (props) => {
    const [t, i18n] = useTranslation('common');

    return (
        <SubscribeForm t={t}/>
    );
};