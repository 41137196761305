import React, { useState } from 'react';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import moment from "moment";
import { sprintf } from 'sprintf-js';

const DateRange = (props) => {

    const ts = moment.utc(props.timestamp);

    const [date, setDate] = useState(ts.format("YYYY-MM-DD"));
    const [time, setTime] = useState(ts.format("HH:mm:ss"));
    const [millis, setMillis] = useState(ts.milliseconds());

    const updateDate = (value) => {
        setDate(value);
        props.setTimestamp(value + "T" + time + "." + sprintf("%03d", millis) + "Z");
    };

    const updateTime = (value) => {
        setTime(value);
        props.setTimestamp(date + "T" + value + "." + sprintf("%03d", millis) + "Z");
    };

    const updateMillis = (value) => {
        if (value === "")
            return;

        const ivalue = parseInt(value);
        if (ivalue < 0 || ivalue >= 1000)
            return;

        setMillis(ivalue);
        props.setTimestamp(date + "T" + time + "." + sprintf("%03d", ivalue) + "Z");
    };

    return (
        <Row className="mb-2">
            <Col md={2}>
                <Form.Label>{props.label}</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    type="date"
                    value={date}
                    onChange={e => updateDate(e.target.value)}
                />
            </Col>
            <Col>
                <Form.Control
                    type="time"
                    step="1"
                    value={time}
                    onChange={e => updateTime(e.target.value)}
                />
            </Col>
            <Col>
                <Form.Control
                    type="number"
                    placeholder="Milliseconds"
                    value={millis}
                    onChange={e => updateMillis(e.target.value)}
                    min="0"
                    max="999"
                />
            </Col>
        </Row>
    )
};

const DateRangePicker = (props) => {
  return (
    <>
        <DateRange label="since (UTC)" timestamp={props.since} setTimestamp={props.setSince} />
        <DateRange label="until (UTC)" timestamp={props.until} setTimestamp={props.setUntil} />
    </>
  );
};

export default DateRangePicker;
