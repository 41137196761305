
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { LuArchiveRestore } from 'react-icons/lu';
import { FiHardDrive } from 'react-icons/fi';

import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import { SwalWithBootstrapButtons, showError } from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Table, Tooltip, FormCheck, OverlayTrigger, Modal } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

const MyModal = (props) => {
  const [mountPoint, setMountPoint] = useState("X:");
  const [machineId, setMachineId] = useState("unknown");
  const [machines, setMachines] = useState([]);

  const handleClose = () => {
    props.hideCallback();
  };

  const handleApply = async () => {
    props.hideCallback();

    const result = await getBackend().mountMachineVolume(machineId, props.volumeId, mountPoint);
    if (result.error === null) {
      window.location.href = Routes.Mounts.path + "/" + machineId;
    } else {
      await showError(result.error);
    }
  };

  useEffect(() => {
    let canceled = false;

    const getComputers = async () => {
      const result = await getBackend().machines();
      if (canceled)
        return;

      if (result.error == null) {
        setMachines(result.response.machines);
      } else {
        if (!canceled)
          await showError(result.error);
      }
    };

    getComputers();
    return () => {
      canceled = true;
    }
  }, []);

  const onMachineSelect = (e) => {
    if (e.target.value !== "unknown") {
      setMachineId(e.target.value);
    }
  };

  return (
    <Modal show="true" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Mount volume {props.volumeId} at online computer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Select onChange={onMachineSelect}>
        <option value="unknown" key="-1">Select computer where to mount volume</option>
        {machines.map((item, index) => (
          <option value={item.machine_id} key={index}>{item.machine_id} - {item.hostname}</option>
        ))}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Apply
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

const MountVolumeMenu = (props) => {
  const [show, setShow] = useState(false);

  const hideCallback = () => {
    setShow(false);
  }

  return (
    <>
      {show && <MyModal hideCallback={hideCallback} volumeId={props.volumeId}/>}
      <Dropdown.Item className="d-flex align-items-center" onClick={() => { setShow(true);} }>
        <FiHardDrive className="dropdown-icon text-normal me-2" />
        Mount
      </Dropdown.Item>
    </>
  )
}


const VolumesTable = (props) => {
  const { volumes = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = volumes.filter(u => u.isSelected).length === 0;

  const selectVolume = (id) => {
    props.selectVolume && props.selectVolume(id);
  };

  const selectAllVolumes = () => {
    props.selectAllVolumes && props.selectAllVolumes();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteVolumes();
  }

  const deleteVolumes = (ids) => {
    props.deleteVolumes && props.deleteVolumes(ids)
  }

  const TableRow = (props) => {
    const { volume_id, volume_name, fs_name, size, isSelected } = props;

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`volume-${volume_id}`} checked={isSelected} onChange={() => selectVolume(volume_id)} />
            <FormCheck.Label htmlFor={`volume-${volume_id}`} />
          </FormCheck>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center" as={Link} to={Routes.Volume.path + "/" + volume_id}>
            <div className="d-block">
              <span className="fw-normal">{volume_id}</span>
            </div>
          </Card.Link>
        </td>
        <td><span className="fw-normal">{volume_name}</span></td>
        <td>
          <span className={`fw-normal`}>{fs_name}</span>
        </td>
        <td>
          <span className={`fw-normal`}>{size}</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">

              <MountVolumeMenu volumeId={volume_id}/>
              <Dropdown.Item className="d-flex align-items-center" as={Link} to={Routes.Recovery.path + "/" + volume_id}>
                <LuArchiveRestore className="dropdown-icon text-normal me-2" />
                Restore
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteVolumes([volume_id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="delete">Delete</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllVolumes} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Id</th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Filesystem</th>
              <th className="border-bottom">Size</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {volumes.map(u => <TableRow key={`volume-${u.volume_id}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ({match}) => {
  const backup_id = match.params.id;

  const [volumes, setVolumes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const selectedVolumesIds = volumes.filter(u => u.isSelected).map(u => u.volume_id);
  const totalVolumes = volumes.length;
  const allSelected = selectedVolumesIds.length === totalVolumes;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newVolumes = volumes.map(u => ({ ...u, show: u.volume_name.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setVolumes(newVolumes);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newVolumes = volumes.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setVolumes(newVolumes);
  };

  const selectAllVolumes = () => {
    const newVolumes = selectedVolumesIds.length === totalVolumes ?
    volumes.map(u => ({ ...u, isSelected: false })) :
    volumes.map(u => ({ ...u, isSelected: true }));

    setVolumes(newVolumes);
  };

  const selectVolume = (id) => {
    const newVolumes = volumes.map(u => u.volume_id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setVolumes(newVolumes);
  };

  const deleteVolumes = async (ids) => {
    const volumesToBeDeleted = ids ? ids : selectedVolumesIds;
    const volumesNr = volumesToBeDeleted.length;
    const textMessage = volumesNr === 1
      ? "Are you sure do you want to delete this computer?"
      : `Are you sure do you want to delete these ${volumesNr} volumes?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newVolumes = volumes.filter(f => !volumesToBeDeleted.includes(f.volume_id));
      const confirmMessage = volumesNr === 1 ? "The computer has been deleted." : "The volumes have been deleted.";

      setVolumes(newVolumes);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  useEffect(() => {
    let canceled = false;

    const getBackup = async () => {
      const result = await getBackend().backup(backup_id);
      if (canceled)
        return;

      if (result.error == null) {
          setVolumes(result.response.volumes.map(u => ({ ...u, isSelected: false, show: true, status: "complete"})));
      } else {
          window.location.href = Routes.NotFound.path;
      }
    };

    getBackup();

    return async () => {
      canceled = true;
    }
  }, [backup_id]);

  return (
    <>
      <Helmet>
        <title>Cydanix Drive Backup cydanix.com</title>
        <meta name="description" content="Cydanix drive backup" />
      </Helmet>
      <SidebarPageHeader pageName="Drive Backup" pageTitle={"Drive Backup " + backup_id} />
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search volumes"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="complete">Complete</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
            </Form.Select>
          </Col>
        </Row>
      </div>

      <VolumesTable
        volumes={volumes.filter(u => u.show).sort(
          (a, b) => {
            if (a.volume_name === b.volume_name)
              return 0;
            if (a.volume_name === "")
              return 1;
            if (b.volume_name === "")
              return -1;

            if (a.volume_name < b.volume_name)
              return -1;
            if (a.volume_name > b.volume_name)
              return 1;
            return 0;
          }
        )}
        allSelected={allSelected}
        selectVolume={selectVolume}
        deleteVolumes={deleteVolumes}
        selectAllVolumes={selectAllVolumes}
      />
    </>
  );
};
