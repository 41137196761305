
import React from "react";
import ApexChart from "react-apexcharts";

export const MyValueChart = ({name, labels, data }) => {

  const chartSeries = [
    {
      name: name,
      data: data,
    }
  ];

  const chartOptions = {
    chart: {
      fontFamily: 'Inter',
      foreColor: '#262B40',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          }
        },
        autoSelected: 'zoom'
      },
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      style: {
        fontSize: '14px',
        fontFamily: 'Inter',
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#17a5ce',
      }
    },
    grid: {
      show: true,
      borderColor: '#61DAFB',
      strokeDashArray: 1,
    },
    markers: {
      size: 5,
      strokeColors: '#17a5ce',
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
        },
      },
      axisBorder: {
        color: '#61DAFB',
      },
      axisTicks: {
        color: '#61DAFB',
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#262B40'],
          fontSize: '12px',
          fontWeight: 500,
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          yaxis: {
            show: false,
          }
        }
      }
    ]
  };

  return (
    <ApexChart
      type="area"
      height={420}
      series={chartSeries}
      options={chartOptions}
    />
  );
};
