

export const Routes = {
    Presentation: { path: "/" },
    Dashboard: { path: "/dashboard" },
    Billing: { path: "/account/billing" },
    Signin: { path: "/account/sign-in" },
    Signup: { path: "/account/sign-up" },
    Settings: { path: "/account/settings" },
    Verification: { path: "/account/verification" },
    Notifications: { path: "/account/notifications" },
    Lock: { path: "/account/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Hosts: {path: "/hosts"},
    Host: {path: "/host"},
    Mounts: {path: "/mounts"},
    Backups: {path: "/backups"},
    Backup: {path: "/backup"},
    Volume: {path: "/volume"},
    Setup: {path: "/setup"},
    Blog: {path: "/blog"},
    About: {path: "/about"},
    Contact: {path: "/contact"},
    Recovery: {path: "/recovery"},
    PrivacyPolicy: {path: "/legal/privacy-policy"},
    TermsOfService: {path: "/legal/terms-of-service"},
    Pricing: {path: "/pricing"},
    Business: {path: "/business"},
    AppControl: {path: "/appcontrol"},
    NetFw: {path: "/netfw"},
    Events: {path: "/events"},
    Goodbye: {path: "/goodbye"},
    Alerts: {path: "/alerts"},
};
