
import React, { useState, useEffect } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CSSTransition } from 'react-transition-group';
import { ChartBarIcon, ChevronRightIcon, CogIcon, CreditCardIcon, InboxIcon, PlusIcon, XIcon, LockClosedIcon, ArchiveIcon, SearchIcon, GlobeIcon, BellIcon, DesktopComputerIcon} from "@heroicons/react/solid";
import { Nav, Badge, Image, Button, Dropdown, Navbar, Collapse, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";
import CydanixLogo from "assets/img/cydanix.svg";
import getBackend from "backend/backend";
import {useTranslation} from "react-i18next";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";
  const [unreadNotificationsCounter, setUnreadNotificationsCounter] = useState(0);
  const [unreadAlertsCounter, setUnreadAlertsCounter] = useState(0);
  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some(item => item.includes(itemKey));
    const newCollapsedItems = isCollapsed ? collapsedItems.filter(item => !item.includes(itemKey)) : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon: NavItemIcon, children = null } = props;
    const isOpened = collapsedItems.some(item => item.includes(eventKey));

    return (
      <Nav.Item>
        <Nav.Link
          onClick={() => onNavItemCollapse(eventKey)}
          aria-expanded={isOpened}
          aria-controls={eventKey}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <NavItemIcon className="icon icon-xs me-2" />
            </span>
            <span className="sidebar-text">
              {title}
            </span>
          </span>
          <span className="link-arrow">
            <ChevronRightIcon className="icon icon-sm" />
          </span>
        </Nav.Link>
        <Collapse in={isOpened} className="multi-level">
          <div id={eventKey}>
            {children}
          </div>
        </Collapse>
      </Nav.Item>
    );
  };

  const NavItem = (props) => {
    const { title, link, target, icon: NavItemIcon, image, badgeText, badgeBg, badgeColor = "white" } = props;
    const classNames = badgeText ? "d-flex align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted">
                {title[0]}
              </span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-sm notification-count">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  useEffect(() => {
    let canceled = false;
    const getAccount = async () => {
      const result = await getBackend().account()
      if (canceled)
        return;

      if (result.error !== null) {
          window.location.href = Routes.Signin.path;
      }
    };

    const getUnreadNotificationsCounter = async () => {
      const result = await getBackend().getUnreadNotificationsCounter();
      if (canceled)
        return;

      if (result.error == null) {
        setUnreadNotificationsCounter(result.response.counter);
      }
    };

    const getUnreadAlertsCounter = async () => {
      const result = await getBackend().getUnreadAlertsCounter();
      if (canceled)
        return;

      if (result.error == null) {
        setUnreadAlertsCounter(result.response.counter);
      }
    };

    getAccount();
    getUnreadNotificationsCounter();
    getUnreadAlertsCounter();

    return () => {
      canceled = true;
    }
  }, []);

  const [t, i18n] = useTranslation('common');

  return (
    <>
      <Navbar as={Col} xs={12} expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-lg-none">
        <Navbar.Brand as={Link} to={Routes.Dashboard.path} className="me-lg-5">
          <Image src={CydanixLogo} className="navbar-brand-dark" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${contracted} ${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4">
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Cydanix" link={Routes.Presentation.path} image={CydanixLogo} />

              <NavItem title={t('Dashboard')} icon={ChartBarIcon} link={Routes.Dashboard.path} />

              <NavItem title={t('Hosts')} icon={DesktopComputerIcon} link={Routes.Hosts.path} />
              <NavItem title={t('Add Host')} icon={PlusIcon} link={Routes.Setup.path} />

              <NavItem title={t('Drive Backups')} icon={ArchiveIcon} link={Routes.Backups.path} />
              <NavItem title={t('Application Control')} icon={LockClosedIcon} link={Routes.AppControl.path} />
              <NavItem title={t('Network Firewall')} icon={GlobeIcon} link={Routes.NetFw.path} />
              <NavItem title={t('Event Explorer')} icon={SearchIcon} link={Routes.Events.path} />

              {unreadAlertsCounter
                ? <NavItem title={t('Security Alerts')} icon={BellIcon} badgeText={`${unreadAlertsCounter}`} badgeBg="danger" link={Routes.Alerts.path} />
                : <NavItem title={t('Security Alerts')} icon={BellIcon} link={Routes.Alerts.path} />
              }

              <NavItem title={t('Settings')} icon={CogIcon} link={Routes.Settings.path} />
              <NavItem title={t('Billing')} icon={CreditCardIcon} link={Routes.Billing.path} />
              {unreadNotificationsCounter
                ? <NavItem title={t('Notifications')} icon={InboxIcon} badgeText={`${unreadNotificationsCounter}`} badgeBg="warning" link={Routes.Notifications.path} />
                : <NavItem title={t('Notifications')} icon={InboxIcon} link={Routes.Notifications.path} />
              }
              <Dropdown.Divider className="my-3 border-indigo" />


            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
