
import React from "react";
import {Button, Container, Navbar, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";
import CydanixLogo from "assets/img/cydanix.svg";

import { useTranslation } from "react-i18next";
import { ConsoleGate } from "./ConsoleGate";

import { signin_ex } from "account/Signin";

export default (props) => {

    const [t, i18n] = useTranslation('common');

    const demoSignin = () => {
      signin_ex("email", "demo@cydanix.com", "1q2w3eQAZ");
    }

    return (
        <>
          <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
            <Container className="position-relative justify-content-between px-3">
              <Navbar.Brand className="me-lg-3 d-flex align-items-center" as={Link} to={Routes.Presentation.path}>
                <Image src={CydanixLogo} />
                <span className="ms-2 brand-text d-none d-md-inline">Cydanix</span>
              </Navbar.Brand>
              <div className="d-flex align-items-center">
                <Navbar.Collapse id="navbar-default-primary">
                <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>{t('Home')}</Nav.Link>
                <Nav.Link as={Link} to={Routes.Business.path}>{t('Business')}</Nav.Link>
                <Nav.Link as={Link} to={Routes.Pricing.path}>{t('Pricing')}</Nav.Link>
                <Nav.Link as={Link} to={Routes.Blog.path}>{t('Blog')}</Nav.Link>
                <Nav.Link onClick={(e)=> { e.preventDefault(); window.location.href="mailto:support@cydanix.com";}}>{t('Contact Us')}</Nav.Link>
                </Nav>
                </Navbar.Collapse>
                <Button onClick={demoSignin} variant="outline-white" className="ms-3" >{t('DEMO')}</Button>

                <Button as={Link} to={Routes.Signup.path} variant="outline-white" className="ms-3" >{t('Get Started for FREE')}</Button>
                <ConsoleGate t={t}/>
            </div>
            </Container>
          </Navbar>
        </>
    )
}