import React from "react";
import getBackend from "backend/backend";
import { Routes } from "routes";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class ConsoleGate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {email: null, machine_count: 0};
        this.onClick = this.onClick.bind(this);
    }
  
    async componentDidMount() {
        const result = await getBackend().account();
        const email = (result.error === null) ? result.response.email : null;
        const machine_count = (result.error === null) ? result.response.machine_count : 0;
  
        this.setState(state => ({
            email: email,
            machine_count: machine_count
        }));
    }
  
    onClick(e) {
        if (this.state.email == null) {
          e.preventDefault();
          window.location.href = Routes.Signin.path;
          return;
        }
    }
  
    render() {
      return (
        <Button as={Link} to={this.state.machine_count ? Routes.Dashboard.path : Routes.Setup.path} variant="outline-white" className="ms-3" onClick={this.onClick}>{this.props.t('Sign in')}</Button>
      );
    }
  }