
import React, { useState, useEffect, useRef } from "react";
import {  SearchIcon, ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown, Modal, Alert } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import {timestampToString, capitalizeFirstLetter, SwalWithBootstrapButtons, showError} from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Image, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";
import { showMessage } from "util/util";
import validator from 'validator';
import { sprintf } from "sprintf-js";
import { saveAs } from 'file-saver';

const RulesTable = (props) => {
  const { rules = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = rules.filter(u => u.isSelected).length === 0;

  const selectRule = (id) => {
    props.selectRule && props.selectRule(id);
  };

  const selectAllRules = () => {
    props.selectAllRules && props.selectAllRules();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteRules();
  }

  const deleteRules = (ids) => {
    props.deleteRules && props.deleteRules(ids)
  }

  const moveUp = (id) => {
    props.moveUp && props.moveUp(id)
  }

  const moveDown = (id) => {
    props.moveDown && props.moveDown(id)
  }

  const moveTop = (id) => {
    props.moveTop && props.moveTop(id)
  }

  const moveBottom = (id) => {
    props.moveBottom && props.moveBottom(id)
  }

  const getDetails = (type, signIssuer, signSubject, sha256, path) => {
    switch(type) {
    case "sign":
      return sprintf("%s => %s", signIssuer, signSubject);
    case "path":
      return path;
    case "sha256":
      return sha256;
    default:
      return "";
    }
  }

  const TableRow = (props) => {
    const { id, allow, protocol, direction, local_address, local_port_start, local_port_end, remote_address, remote_port_start, remote_port_end, content_type, isSelected} = props;

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`rule-${id}`} checked={isSelected} onChange={() => selectRule(id)} />
            <FormCheck.Label htmlFor={`rule-${id}`} />
          </FormCheck>
        </td>
        <td>
              <span className="fw-normal">{allow ? "allow" : "block"}</span>
        </td>
        <td>
              <span className="fw-normal">{protocol}</span>
        </td>
        <td>
              <span className="fw-normal">{direction}</span>
        </td>
        <td>
              <span className="fw-normal">{local_address} : {local_port_start}-{local_port_end}</span>
        </td>
        <td>
              <span className="fw-normal">{remote_address} : {remote_port_start}-{remote_port_end}</span>
        </td>
        <td>
              <span className="fw-normal">{content_type}</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => moveUp(id)}>
                <ArrowUpIcon className="dropdown-icon text-gray-400 me-2" />
                Move up
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={() => moveDown(id)}>
                <ArrowDownIcon className="dropdown-icon text-gray-400 me-2" />
                Move down
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={() => moveTop(id)}>
                <ArrowUpIcon className="dropdown-icon text-gray-400 me-2" />
                Move top
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={() => moveBottom(id)}>
                <ArrowDownIcon className="dropdown-icon text-gray-400 me-2" />
                Move bottom
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteRules([id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>

        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="delete">Delete</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllRules} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Action</th>
              <th className="border-bottom">Protocol</th>
              <th className="border-bottom">Direction</th>
              <th className="border-bottom">Local address</th>
              <th className="border-bottom">Remote address</th>
              <th className="border-bottom">Content type</th>
              <th className="border-bottom">More</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {rules.map(u => <TableRow key={`rule-${u.id}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const isStrIntegerInRage = (str, start, end) => {
  const isInteger = /^\d+$/.test(str);

  if (!isInteger) {
      return false;
  }

  const number = Number(str);
  return Number.isInteger(number) && number >= start && number <= end;
}

const validateRule = (rule) => {

  if (typeof rule.allow !== "boolean") {
    return "invalid type of allow field";
  }

  if (rule.local_port_start < 0 || rule.local_port_start > 65535) {
    return "invalid local port start value";
  }

  if (rule.local_port_end < 0 || rule.local_port_end > 65535) {
      return "invalid local port end value";
  }

  if (rule.remote_port_start < 0 || rule.remote_port_start > 65535) {
      return "invalid remote port start value";
  }

  if (rule.remote_port_end < 0 || rule.remote_port_end > 65535) {
      return "invalid remote port end value";
  }

  if (rule.local_port_end < rule.local_port_start) {
      return "invalid local port end value";
  }

  if (rule.remote_port_end < rule.remote_port_start) {
      return "invalid remote port end value";
  }

  if (rule.address_family !== "inet" && rule.address_family !== "inet6") {
    return "invalid address family";
  }

  if (rule.protocol !== "tcp" && rule.protocol !== "udp" && rule.protocol !== "icmp") {
    return "invalid protocoal";
  }

  if (rule.direction !== "outbound" && rule.direction !== "inbound") {
    return "invalid direction";
  }

  if (rule.local_address !== "*") {
      const words = rule.local_address.split("/");
      if (words.length > 2) {
        return "invalid local address";
      }

      if (words.length === 2) {
        if (!isStrIntegerInRage(words[1], 0, (rule.address_family === "inet") ? 32 : 128)) {
          return "invalid local address";
        }
      }

      if (!validator.isIP(words[0], (rule.address_family === "inet") ? "4" : "6")) {
          return "invalid local address";
      }
  }

  if (rule.remote_address !== "*") {
      const words = rule.remote_address.split("/");
      if (words.length > 2) {
        return "invalid local address";
      }

      if (words.length === 2) {
        if (!isStrIntegerInRage(words[1], 0, (rule.address_family === "inet") ? 32 : 128)) {
          return "invalid local address";
        }
      }

      if (!validator.isIP(words[0], (rule.address_family === "inet") ? "4" : "6")) {
          return "invalid remote address";
      }
  }

  if (rule.content_type !== "*") {
    if (rule.content_type !== "ssh" && rule.content_type !== "smb") {
      return "invalid content type";
    }
  }

  return null;
}

const AddRuleDialog = (props) => {
    const [type, setType] = useState("");
    const [allow, setAllow] = useState(false);
    const [protocol, setProtocol] = useState("tcp");
    const [direction, setDirection] = useState("outbound");
    const [addressFamily, setAddressFamily] = useState("inet");
    const [localPortStart, setLocalPortStart] = useState("0");
    const [localPortEnd, setLocalPortEnd] = useState("65535");
    const [remotePortStart, setRemotePortStart] = useState(0);
    const [remotePortEnd, setRemotePortEnd] = useState("65535");
    const [localAddress, setLocalAddress] = useState("*");
    const [remoteAddress, setRemoteAddress] = useState("*");
    const [contentType, setContentType] = useState("*");
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");

    const handleClose = () => {
        setType("");
        setAllow(false);
        setProtocol("tcp");
        setDirection("outbound");
        setAddressFamily("inet");
        setLocalPortStart(0);
        setLocalPortEnd(65535);
        setRemotePortStart(0);
        setRemotePortEnd(65536);
        setLocalAddress("*");
        setRemoteAddress("*");
        setContentType("*");
        setError("");
        setShow(false);
        props.setShow(false);
    }

    const onSubmit = async (e) => {

        const local_port_start = parseInt(localPortStart);
        const local_port_end = parseInt(localPortEnd);
        const remote_port_start = parseInt(remotePortStart);
        const remote_port_end = parseInt(remotePortEnd);
        const rule = {type: type, allow: allow, protocol: protocol, direction: direction, 
          address_family: addressFamily, local_port_start: local_port_start, local_port_end: local_port_end,
          remote_port_start: remote_port_start, remote_port_end: remote_port_end,
          local_address: localAddress, remote_address: remoteAddress,
          content_type: contentType
          };

        let err = validateRule(rule);
        if (err !== null) {
          setError(err);
          return;
        }

        err = await props.addRule(rule);
        if (err !== null) {
            setError(err);
            return;
        }
        handleClose();
    }
  
    useEffect(() => {
      setShow(props.show);
    }, [props.show]);
  
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add rule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Protocol</Form.Label>
                <Form.Select onChange={(e) => {setProtocol(e.target.value);}}>
                  <option value="tcp">tcp</option>
                  <option value="udp">udp</option>
                  <option value="icmp">icmp</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Direction</Form.Label>
                <Form.Select onChange={(e) => {setDirection(e.target.value);}}>
                  <option value="outbound">outbound</option>
                  <option value="inbound">inbound</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Address Family</Form.Label>
                <Form.Select onChange={(e) => {setAddressFamily(e.target.value);}}>
                  <option value="inet">inet</option>
                  <option value="inet6">inet6</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
              <Form.Label>Local Address</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Local address (IP)"
                      required
                      onChange={(e) => {setLocalAddress(e.target.value);}}
                  />
              </Form.Group>

              <Form.Group className="mb-3">
              <Form.Label>Local Port Range</Form.Label>
                <Row>
                  <Col>
                  <Form.Control
                      type="number"
                      placeholder="0-65535"
                      required
                      onChange={(e) => {setLocalPortStart(e.target.value);}}
                      min="0"
                      max="65535"
                  />
                  </Col>
                  <Col>
                  <Form.Control
                      type="number"
                      placeholder="0-65535"
                      required
                      onChange={(e) => {setLocalPortEnd(e.target.value);}}
                      min="0"
                      max="65535"
                  />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
              <Form.Label>Remote Address</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Remote address (IP)"
                      required
                      onChange={(e) => {setRemoteAddress(e.target.value);}}
                  />
              </Form.Group>

              <Form.Group className="mb-3">
              <Form.Label>Remote Port Range</Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                        type="number"
                        placeholder="0-65535"
                        required
                        onChange={(e) => {setRemotePortStart(e.target.value);}}
                        min="0"
                        max="65535"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                        type="number"
                        placeholder="0-65535"
                        required
                        onChange={(e) => {setRemotePortEnd(e.target.value);}}
                        min="0"
                        max="65535"
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Content type</Form.Label>
                <Form.Select onChange={(e) => {setContentType(e.target.value);}}>
                  <option value="ssh">*</option>
                  <option value="ssh">ssh</option>
                  <option value="smb">smb</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Action</Form.Label>
                <Form.Select onChange={(e) => {setAllow((e.target.value === "allow") ? true : false);}}>
                  <option value="block">block</option>
                  <option value="allow">allow</option>
                </Form.Select>
              </Form.Group>

              <Button variant="primary" onClick={onSubmit}>
                Apply
              </Button>
              {error !== "" &&
              <Alert variant="danger">
                {error}
              </Alert>
              }
            </Form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </>
    )
}

export default () => {

    const [rules, setRules] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [updateSeqno, setUpdateSeqno] = useState(0);
    const selectedRulesIds = rules.filter(u => u.isSelected).map(u => u.id);
    const totalRules = rules.length;
    const allSelected = selectedRulesIds.length === totalRules;

    const [addRuleShow, setAddRuleShow] = useState(false);

    const defaultRules = [];

    const changeSearchValue = (e) => {
      const newSearchValue = e.target.value;
      const newRules = rules.map(u => ({ ...u, show: u.path.toLowerCase().includes(newSearchValue.toLowerCase()) }));

      setSearchValue(newSearchValue);
      setRules(newRules);
    };

    const selectAllRules = () => {
      const newRules = selectedRulesIds.length === totalRules ?
      rules.map(u => ({ ...u, isSelected: false })) :
      rules.map(u => ({ ...u, isSelected: true }));

      setRules(newRules);
    };

    const selectRule = (id) => {
      const newRules = rules.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
      setRules(newRules);
    };

    const applyRules = async (newRules) => {
      const result = await getBackend().setNetFwPolicy({rules: newRules});
      if (result.error !== null) {
          return result.error;
      }

      setRules(newRules);
      return null;
    }

    const addRule = async (rule) => {
        let newRules = [];
        const result = await getBackend().getNetFwPolicy();
        if (result.error !== null) {
            if (!result.error.endsWith("no rows in result set")) {
                return result.error
            }
        } else {
            if (result.response.policy.rules !== null) {
              for (let i = 0; i < result.response.policy.rules.length; i++) {
                  newRules.push({...result.response.policy.rules[i], id: i, show: true, isSelected: false});
              }
            }
        }

        newRules.push({...rule, id: newRules.length, show: true, isSelected: false});
        return applyRules(newRules);
    }

  const rulesExchange = async(m, n) => {
    let newRules = [];

    for (let i = 0; i < rules.length; i++) {
      newRules.push({...rules[i], id: i, show: true, isSelected: false});
    }

    let tmp = newRules[m];
    newRules[m] = {...newRules[n], id: m, show: true, isSelected: false};
    newRules[n] = {...tmp, id: n, show: true, isSelected: false};
    return applyRules(newRules);
  }

  const moveUp = async (id) => {
    if (id === 0)
      return;

    rulesExchange(id, id-1);
  }

  const moveDown = (id) => {
    if (id === rules.length-1)
      return;

    rulesExchange(id, id+1);
  }

  const moveTop = (id) => {
    if (id === 0)
      return;

    let newRules = [];
    let j = 0;

    newRules.push({...rules[id], id: j++, show: true, isSelected: false});

    for (let i = 0; i < rules.length; i++) {
      if (i === id)
        continue;

      newRules.push({...rules[i], id: j++, show: true, isSelected: false});
    }

    return applyRules(newRules);
  }

  const moveBottom = (id) => {
    if (id === rules.length-1)
      return;

      let newRules = [];
      let j = 0;

      for (let i = 0; i < rules.length; i++) {
        if (i === id)
          continue;

        newRules.push({...rules[i], id: j++, show: true, isSelected: false});
      }

      newRules.push({...rules[id], id: j++, show: true, isSelected: false});

      return applyRules(newRules);
  }

  const deleteRules = async (ids) => {
    const rulesToBeDeleted = ids ? ids : selectedRulesIds;
    const textMessage = rulesToBeDeleted.length === 1
      ? "Are you sure do you want to delete this rule?"
      : `Are you sure do you want to delete these ${rulesToBeDeleted.length} rules?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newRules = rules.filter(f => !rulesToBeDeleted.includes(f.id));
      const confirmMessage = rulesToBeDeleted.length === 1 ? "The rule has been deleted." : "The rules have been deleted.";

      await getBackend().setNetFwPolicy({rules: newRules});

      setRules(newRules);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const setDefaultRules = async () =>  {
    await getBackend().setNetFwPolicy({rules: defaultRules});
    let newRules = [];
    for (let i = 0; i < defaultRules.length; i++) {
      newRules.push({...defaultRules[i], id: i, show: true, isSelected: false})
    }
    setRules(newRules);
  };

  useEffect(() => {
    let canceled = false;

    const getRules = async () => {
        const result = await getBackend().getNetFwPolicy();
        if (canceled)
            return;
        
        let rules = []
        if (result.error !== null) {
            if (!result.error.endsWith("no rows in result set")) {
                if (!canceled)
                    await showError(result.error);

                return;
            }
        } else {
          if (result.response.policy.rules !== null) {
            for (let i = 0; i < result.response.policy.rules.length; i++) {
              rules.push({...result.response.policy.rules[i], id: i, show: true, isSelected: false});
            }
          }
        }

        setRules(rules);
    };

    getRules();
    return () => {
      canceled = true;
    }
  }, [updateSeqno]);

  const exportRules = (e) => {
    e.preventDefault();
    saveAs(new Blob([JSON.stringify({rules: rules}, null, 2)]), "cydanix_network_firewall_rules.txt");
  }

  const importRulesFileInputRef = useRef(null);

  const importRulesFileChange = (e) => {
    const file = e.target.files[0];
    if (!file)
      return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;

      try {
        const policy = JSON.parse(content);

        for (let index = 0; index < policy.rules.length; index++) {
          const err = validateRule(policy.rules[index]);
          if (err !== null) {
            await showError("rule " + index + " invalid: " + err);
            return;
          }
        }

        const error = await applyRules(policy.rules);
        if (error) {
          await showError(error);
          return;
        }
        setUpdateSeqno(updateSeqno + 1);
      } catch (error) {
        await showError(error);
      }
    };
    reader.readAsText(file);
    e.target.value = null;
  };

  const importRules = (e) => {
    importRulesFileInputRef.current.click();
  }

  return (
    <>
      <Helmet>
        <title>Cydanix Network Firewall cydanix.com</title>
        <meta name="description" content="Cydanix network firewall" />
      </Helmet>
      <SidebarPageHeader pageName="Network Firewall"/>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search rules"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>

            <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {e.preventDefault(); setDefaultRules();}}>
                Reset to default rules<span className="icon icon-xs ms-3" />
            </Button>

            <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {e.preventDefault(); setAddRuleShow(true);}}>
                Add rule<span className="icon icon-xs ms-3" />
            </Button>

            <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {exportRules(e);}}>
              Export rules<span className="icon icon-xs ms-3" />
            </Button>

            <Form.Control
              type="file"
              ref={importRulesFileInputRef}
              onChange={importRulesFileChange}
              style={{ display: 'none' }} // Hide the file input
            />
            <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2" onClick={(e) => {importRules(e);}}>
              Import rules<span className="icon icon-xs ms-3" />
            </Button>

          </Col>
        </Row>
      </div>

      <RulesTable
        rules={rules}
        allSelected={allSelected}
        selectRule={selectRule}
        deleteRules={deleteRules}
        selectAllRules={selectAllRules}
        moveUp={moveUp}
        moveDown={moveDown}
        moveTop={moveTop}
        moveBottom={moveBottom}
      />

        <AddRuleDialog show={addRuleShow} setShow={setAddRuleShow} addRule={addRule}/>

    </>
  );
};
