import SidebarPageHeader from "components/SidebarPageHeader";

import getBackend from "backend/backend";
import React, { useState, useEffect} from "react";
import ReactGA from "react-ga4";
import { Col, Row, Card, Button, Container, Form, InputGroup, Alert} from 'react-bootstrap';
import {SwalWithBootstrapButtons, showError} from "util/util";
import {Helmet} from "react-helmet";

const MyRange = ({ name, applyHandler, initialValue, min, max}) => {
    let [pos, setPos] = useState(initialValue);

    const onChange = async (e) => {
        applyHandler(e.target.value);
        setPos(e.target.value);
    }

    useEffect(() => {
        setPos(initialValue);
    }, [initialValue]);

    return (
        <Container>
            <h5 className="mb-4">{name}: {pos}</h5>
            <Form>
                <InputGroup className="mb-3">
                <Form.Range value={pos} onChange={onChange} min={min} max={max}/>
                </InputGroup>
            </Form>
        </Container>
    )
}

const MyFormCheck = ({ name, applyHandler, initialValue}) => {
    let [checked, setChecked] = useState(initialValue);

    const onChange = async (e) => {
        applyHandler(e.target.checked)
        setChecked(e.target.checked);
    }

    useEffect(() => {
        setChecked(initialValue);
    }, [initialValue]);

    return (
        <Form.Check
            type="switch"
            label={name}
            checked={checked}
            onChange={onChange}
        />
    )
}

const MyChangePassword = ({applyHandler}) => {
    let [error, setError] = useState(null);
    let [currentPassword, setCurrentPassword] = useState("");
    let [newPassword, setNewPassword] = useState("");
    let [newPassword2, setNewPassword2] = useState("");

    const onCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    }

    const onNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    }

    const onNewPassword2Change = (e) => {
        setNewPassword2(e.target.value);
    }

    const onClick = async (e) => {
        e.preventDefault();

        ReactGA.event({
            category: "settings",
            action: "change_password",
          });

        if (currentPassword === undefined ||
            currentPassword === "") {
            setError("Error: current password empty or not defined");
            return;
        }

        if (newPassword === undefined ||
            newPassword === "") {
            setError("Error: new password empty or not defined");
            return;
        }

        if (newPassword !== newPassword2) {
            setError("Error: new passwords mismatch");
            return;
        }

        if (newPassword === currentPassword) {
            setError("Error: new password equals to current password");
            return;
        }

        const err = await applyHandler(currentPassword, newPassword);
        if (err !== null) {
            setError(err);
        }
    }

    return (
        <Card border="0" className="shadow mb-4">
        <Card.Body>
            <h5 className="mb-4">Change account password</h5>
            <Form>
            <InputGroup className="mb-3">
                <Form.Control
                    type="password"
                    id="current_password"
                    placeholder="current password"
                    onChange={onCurrentPasswordChange}
                />
                </InputGroup>
                <InputGroup className="mb-3">
                <Form.Control
                    type="password"
                    id="new_password"
                    placeholder="new password"
                    onChange={onNewPasswordChange}
                />
                </InputGroup>
                <InputGroup className="mb-3">
                <Form.Control
                    type="password"
                    id="new_password_confirm"
                    placeholder="new password confirmation"
                    onChange={onNewPassword2Change}
                />
                </InputGroup>
                <Button id="change_password_button" onClick={onClick}>
                    Apply
                </Button>
                {error && <Card.Body><Alert variant="danger">{String(error)}</Alert></Card.Body>}
            </Form>
        </Card.Body>
    </Card>

    )
}

const MyS3Creds = ({applyHandler}) => {
    let [error, setError] = useState(null);
    let [bucket, setBucket] = useState("bucket");
    let [region, setRegion] = useState("region");
    let [url, setUrl] = useState("url");
    let [accessKeyId, setAccessKeyId] = useState("access key id");
    let [secretAccessKey, setSecretAccessKey] = useState("secret access key");

    const onBucketChange = (e) => {
        setBucket(e.target.value);
    }

    const onRegionChange = (e) => {
        setRegion(e.target.value);
    }

    const onUrlChange = (e) => {
        setUrl(e.target.value);
    }

    const onAccessKeyIdChange = (e) => {
        setAccessKeyId(e.target.value);
    }

    const onSecretAccessKeyChange = (e) => {
        setSecretAccessKey(e.target.value);
    }

    useEffect(() => {
        let canceled = false;

        const getS3Creds = async () => {
            const result = await getBackend().getS3Creds();
            if (canceled)
                return;

            if (result.error === null) {
                setBucket((result.response.bucket !== "") ? result.response.bucket : "bucket");
                setRegion((result.response.region !== "") ? result.response.region : "region");
                setUrl((result.response.url !== "") ? result.response.url : "url");
                setAccessKeyId((result.response.access_key_id !== "") ? result.response.access_key_id: "access key id");
                setSecretAccessKey((result.response.secret_access_key !== "") ? result.response.secret_access_key: "secret access key");
            }
        };

        getS3Creds();
        return () => {
            canceled = true;
        }
    }, []);

    const onApplyClick = async (e) => {
        e.preventDefault();

        ReactGA.event({
            category: "settings",
            action: "apply_s3_creds",
          });

        if (bucket === undefined ||
            bucket === "") {
            setError("Error: bucket empty or not defined");
            return;
        }

        if (region === undefined ||
            region === "") {
            setError("Error: region empty or not defined");
            return;
        }

        if (url === undefined ||
            url === "") {
            setError("Error: url empty or not defined");
            return;
        }

        if (accessKeyId === undefined ||
            accessKeyId === "") {
            setError("Error: accessKeyId empty or not defined");
            return;
        }

        if (secretAccessKey === undefined ||
            secretAccessKey === "") {
            setError("Error: secretAccessKey empty or not defined");
            return;
        }

        const result = await getBackend().updateS3Creds({bucket: bucket, region: region, url: url, access_key_id: accessKeyId, secret_access_key: secretAccessKey});
        if (result.error !== null) {
            setError(result.error);
        }
    }

    const onResetClick = async (e) => {
        e.preventDefault();

        ReactGA.event({
            category: "settings",
            action: "reset_s3_creds",
          });

        const result = await getBackend().resetS3Creds();
        if (result.error !== null) {
            setError(result.error);
        }
    }

    return (
        <Card border="0" className="shadow mb-4">
            <Card.Body>
                <h5 className="mb-4">Use own S3 storage for backups</h5>
                <Form>
                    <Form.Group id="bucket" className="mb-4">
                    <Form.Label>Bucket</Form.Label>
                    <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        id="bucket"
                        placeholder={bucket}
                        onChange={onBucketChange}
                    />
                    </InputGroup>
                    </Form.Group>

                    <Form.Group id="region" className="mb-4">
                    <Form.Label>Region</Form.Label>
                    <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        id="region"
                        placeholder={region}
                        onChange={onRegionChange}
                    />
                    </InputGroup>
                    </Form.Group>

                    <Form.Group id="url" className="mb-4">
                    <Form.Label>Url</Form.Label>
                    <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        id="url"
                        placeholder={url}
                        onChange={onUrlChange}
                    />
                    </InputGroup>
                    </Form.Group>

                    <Form.Group id="accessKeyId" className="mb-4">
                    <Form.Label>Access key id</Form.Label>
                    <InputGroup className="mb-3">
                    <Form.Control
                        type="password"
                        id="accessKeyId"
                        placeholder={accessKeyId}
                        onChange={onAccessKeyIdChange}
                    />
                    </InputGroup>
                    </Form.Group>

                    <Form.Group id="secretAccessKey" className="mb-4">
                    <Form.Label>Secret access key</Form.Label>
                    <InputGroup className="mb-3">
                    <Form.Control
                        type="password"
                        id="secretAccessKey"
                        placeholder={secretAccessKey}
                        onChange={onSecretAccessKeyChange}
                    />
                    </InputGroup>
                    </Form.Group>

                    <Button id="apply_s3_creds_button" onClick={onApplyClick}>
                        Apply
                    </Button>
                    &nbsp;
                    <Button id="reset_s3_creds_button" onClick={onResetClick}>
                        Reset
                    </Button>

                    {error && <Card.Body><Alert variant="danger">{String(error)}</Alert></Card.Body>}
                </Form>
            </Card.Body>
        </Card>

    )
}

const MyDeleteAccount = ({applyHandler}) => {
    let [error, setError] = useState(null);

    const onClick = async (e) => {
        e.preventDefault();
        setError(null);

        ReactGA.event({
            category: "settings",
            action: "delete_account",
          });

        const result = await SwalWithBootstrapButtons.fire({
            icon: "error",
            title: "Confirm account deletion",
            text: "Are you sure you want delete your account?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
          });

        if (result.isConfirmed) {
            const err = await applyHandler();
            if (err !== null) {
                setError(err);
            } else {
                await SwalWithBootstrapButtons.fire('Deleted', "Account has been scheduled for deletion", 'success');
            }
        }
    }

    return (
        <>
            <Card border="0" className="shadow mb-4">
                <Card.Body>
                    <h5 className="mb-4">Delete account</h5>
                    <Button id="delete_account_button" onClick={onClick}>
                        Delete account
                    </Button>
                    {error && <Card.Body><Alert variant="danger">{String(error)}</Alert></Card.Body>}
                </Card.Body>
            </Card>
        </>
    )
}

export default () => {
    const [backupCount, setBackupCount] = useState(0);
    const [backupPeriod, setBackupPeriod] = useState(0);
    const [backupBandwidth, setBackupBandwidth] = useState(0);
    const [backupStartTime, setBackupStartTime] = useState(0);
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [settingsError, setSettingsError] = useState(null);
    const [settingsSeqNo, setSettingsSeqNo] = useState(0);
    const [backupActive, setBackupActive] = useState(false);
    const [appControlActive, setAppControlActive] = useState(false);
    const [useOwnS3, setUseOwnS3] = useState(false);
    const [netmonFwActive, setNetmonFwActive] = useState(false);
    const [hashGuardActive, setHashGuardActive] = useState(false);
    const [malwareDetectionActive, setMalwareDetectionActive] = useState(false);

    useEffect(() => {
        let canceled = false;

        const getSettings = async () => {
            const result = await getBackend().getAccountSettings();
            if (canceled)
                return;

            if (result.error === null) {
                setBackupBandwidth(result.response.settings.backup_bandwidth);
                setBackupPeriod(result.response.settings.backup_period);
                setBackupCount(result.response.settings.backup_count);
                setBackupStartTime(result.response.settings.backup_start_time);
                setEmailNotifications(result.response.settings.email_notifications);
                setBackupActive(result.response.settings.backup);
                setAppControlActive(result.response.settings.app_control);
                setNetmonFwActive(result.response.settings.netmon_fw);
                setHashGuardActive(result.response.settings.hash_guard);
                setMalwareDetectionActive(result.response.settings.malware_detection);
            }
        };

        getSettings();
        return () => {
            canceled = true;
        }
    }, [settingsSeqNo]);

    const updateBackupPeriod = async (value) => {
        setBackupPeriod(parseInt(value));
    }

    const updateBackupCount = async (value) => {
        setBackupCount(parseInt(value));
    }

    const updateBackupStartTime = async (value) => {
        setBackupStartTime(parseInt(value));
    }

    const updateBackupBandwidth = async (value) => {
        setBackupBandwidth(parseInt(value));
    }

    const updateEmailNotifications = async (checked) => {
        setEmailNotifications(checked);
    }

    const updateSettings = async (backup, app_control, netmon_fw, hash_guard, malware_detection) => {
        ReactGA.event({
            category: "settings",
            action: "update_settings",
          });

        const newSettings = {backup_period: backupPeriod, backup_count: backupCount, backup_bandwidth: backupBandwidth,
                            backup_start_time: backupStartTime, email_notifications: emailNotifications,
                            backup: backup, app_control: app_control, netmon_fw: netmon_fw, hash_guard: hash_guard,
                            malware_detection: malware_detection};

        const result = await getBackend().updateAccountSettings(newSettings);
        if (result.error !== null) {
            await showError(result.error);
            return;
        }
        setSettingsSeqNo(settingsSeqNo + 1);
    }

    const changePassword = async (currentPassword, newPassword) => {
        const result = await getBackend().changePassword(currentPassword, newPassword);
        return result.error;
    }

    const deleteAccount = async () => {
        const result = await getBackend().deleteAccount();
        return result.error;
    }

    const onBackupChange =(e) => {
        setBackupActive(e.target.checked);
        updateSettings(e.target.checked, appControlActive, netmonFwActive, hashGuardActive, malwareDetectionActive);
    }

    const onAppControlChange =(e) => {
        setAppControlActive(e.target.checked);
        updateSettings(backupActive, e.target.checked, netmonFwActive, hashGuardActive, malwareDetectionActive);
    }

    const onNetmonFwChange =(e) => {
        setNetmonFwActive(e.target.checked);
        updateSettings(backupActive, appControlActive, e.target.checked, hashGuardActive, malwareDetectionActive);
    }

    const onHashGuardChange =(e) => {
        setHashGuardActive(e.target.checked);
        updateSettings(backupActive, appControlActive, netmonFwActive, e.target.checked, malwareDetectionActive);
    }

    const onMalwareDetectionChange =(e) => {
        setMalwareDetectionActive(e.target.checked);
        updateSettings(backupActive, appControlActive, netmonFwActive, hashGuardActive, e.target.checked);
    }

    const onUseOwnS3Change =(e) => {
        setUseOwnS3(e.target.checked);
    }

    return (
        <>
            <Helmet>
                <title>Cydanix Account Settings cydanix.com</title>
                <meta name="description" content="Cydanix account settings" />
            </Helmet>
            <Row>
                <Col><SidebarPageHeader pageName="Settings"/></Col>
            </Row>

            <Row className="mb-3">
                <Col md={9}>
                    <Form.Check
                        type="switch"
                        id="backup-switch"
                        label="Drive Backup"
                        checked={backupActive}
                        onChange={onBackupChange}
                    />
                    {backupActive &&
                        <>
                        <Card border="0" className="shadow mb-4">
                            <Card.Body>

                                <MyRange name="Backup period (hours)" applyHandler={updateBackupPeriod} initialValue={backupPeriod} min="2" max="168"/>
                                <MyRange name="Backup start time (hour, GMT)" applyHandler={updateBackupStartTime} initialValue={backupStartTime} min="0" max="23"/>
                                <MyRange name="Backup count limit" applyHandler={updateBackupCount} initialValue={backupCount} min="1" max="5"/>
                                <MyRange name="Backup network bandwidth limit (Mbps)" applyHandler={updateBackupBandwidth} initialValue={backupBandwidth} min="30" max="300"/>

                                <Container>
                                    <Button id="apply_settings_button" onClick={updateSettings}>
                                        Apply
                                    </Button>
                                </Container>
                            </Card.Body>
                        </Card>
                        <Form.Check
                            type="switch"
                            id="use-own-s3-switch"
                            label="Use own S3 storage for backups"
                            checked={useOwnS3}
                            onChange={onUseOwnS3Change}
                        />
                        {useOwnS3 &&
                            <MyS3Creds/>
                        }
                        </>
                    }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={9}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Application Control"
                        checked={appControlActive}
                        onChange={onAppControlChange}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={9}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Network Firewall"
                        checked={netmonFwActive}
                        onChange={onNetmonFwChange}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={9}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Hash Guard"
                        checked={hashGuardActive}
                        onChange={onHashGuardChange}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={9}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Cyber Threat Behavior Detection"
                        checked={malwareDetectionActive}
                        onChange={onMalwareDetectionChange}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={9}>
                    <MyChangePassword applyHandler={changePassword}/>
                    <MyDeleteAccount applyHandler={deleteAccount}/>
                </Col>
            </Row>
        </>
    );
}