import HttpClient from './http';
import devmode from './env';
import {delay} from "util/util";

class Backend {
    constructor() {
        this.signin = this.signin.bind(this);
        this.host_url = (devmode()) ? "http://127.0.0.1:9080" : window.location.origin;
        this.client = new HttpClient(this.host_url);

    }

    async renewToken() {
        for (let i = 0; i < 5; i++) {
            const token = window.localStorage.getItem("token");
            const refresh_token = window.localStorage.getItem("refresh_token");

            const result = await this.client.post('/api/createtoken', {token: token, refresh_token: refresh_token});
            if (result.error == null) {
                window.localStorage.setItem("token", result.response.token);
                window.localStorage.setItem("refresh_token", result.response.refresh_token);
                return;
            }
            await delay(100);
        }
    }

    updateTokenFromResult(result) {
        if (result.error == null) {
            window.localStorage.setItem("token", result.response.token);
            window.localStorage.setItem("refresh_token", result.response.refresh_token);
        } else {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("refresh_token");
        }
    }

    async signin(auth_type, email, password) {
        const result = await this.client.post('/api/signin', { auth_type: auth_type, email: email, password: password});
        this.updateTokenFromResult(result);
        return result;
    }

    async subscribe(email, password) {
        return await this.client.post('/api/subscribe', { email: email});
    }

    async contactSales(request) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/contactsales', request, {"X-Token": token});
    }

    async signup(email, password) {
        return await this.client.post('/api/signup', { email: email, password: password});
    }

    async verify(code) {
        const result = await this.client.post('/api/verify', { code: code});
        this.updateTokenFromResult(result);
        return result;
    }

    async signout() {
        const token = window.localStorage.getItem("token");
        if (token != null) {
            const result = await this.client.post('/api/signout', {}, {"X-Token": token});
            if (result.error == null) {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("refresh_token");
            }
            return result;
        }
        return {response: null, error: null};
    }

    async account() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account', {"X-Token": token});
    }

    async getAccountUsage() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/usage', {"X-Token": token});
    }

    async getAccountUsageStats(timestamp, offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/usagestats?timestamp=' + timestamp + '&offset=' + offset + '&limit=' + limit, {"X-Token": token});
    }

    async getAccountBillingInfo() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/billinginfo', {"X-Token": token});
    }

    async setAccountBillingSession(session_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/billingsession', { session_id: session_id}, {"X-Token": token});
    }

    async updateSubscription(price_lookup_key) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/updatesubscription', {price_lookup_key: price_lookup_key}, {"X-Token": token});
    }

    async updateAccountSettings(settings) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/settings', {settings: settings}, {"X-Token": token});
    }

    async getAccountSettings() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/settings', {"X-Token": token});
    }

    async updateS3Creds(data) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/s3creds/update', data, {"X-Token": token});
    }

    async resetS3Creds(data) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/s3creds/reset', {}, {"X-Token": token});
    }

    async getS3Creds(data) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/s3creds/get', {"X-Token": token});
    }

    async getNotifications(timestamp, offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/notifications?timestamp=' + timestamp + '&offset=' + offset + '&limit=' + limit, {"X-Token": token});
    }

    async getUnreadNotificationsCounter() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/notifications/unread', {"X-Token": token});
    }

    async deleteNotification(notification_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/notification/delete', {notification_id: notification_id}, {"X-Token": token});
    }

    async readNotification(notification_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/notification/read', {notification_id: notification_id}, {"X-Token": token});
    }

    async deleteAccount(notification_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/delete', {}, {"X-Token": token});
    }

    async changePassword(current_password, new_password) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/changepassword', {current_password: current_password, new_password: new_password}, {"X-Token": token});
    }

    async getStats(period) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/stats?period=' + period, {"X-Token": token});
    }

    async getAgent() {
        const token = window.localStorage.getItem("token");
        return await this.client.getBlob('/api/agent/content', {"X-Token": token})
    }

    async getAgentVersion() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/agent/version', {"X-Token": token})
    }

    async machines() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/machines', {"X-Token": token});
    }

    async machine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/machine/' + machineId, {"X-Token": token});
    }

    async deleteMachine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/delete', {machine_id: machineId}, {"X-Token": token});
    }

    async backupMachine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/backup', {machine_id: machineId}, {"X-Token": token});
    }

    async resumeMachine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/resume', {machine_id: machineId}, {"X-Token": token});
    }

    async pauseMachine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/pause', {machine_id: machineId}, {"X-Token": token});
    }

    async updateMachine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/update', {machine_id: machineId}, {"X-Token": token});
    }

    async rebootMachine(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/reboot', {machine_id: machineId}, {"X-Token": token});
    }

    async backups(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/machine/' + machineId + '/backups', {"X-Token": token});
    }

    async mountMachineVolume(machineId, volumeId, mountPoint) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/mount', {machine_id: machineId, volume_id: volumeId, mount_point: mountPoint}, {"X-Token": token});
    }

    async unmountMachineVolume(machineId, mountId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/machine/unmount', {machine_id: machineId, mount_id: mountId}, {"X-Token": token});
    }

    async mounts(machineId) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/machine/' + machineId + '/mounts', {"X-Token": token});
    }

    async backup(backupId) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/backup/' + backupId, {"X-Token": token});
    }

    async deleteBackup(backupId) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/backup', {backup_id: backupId}, {"X-Token": token});
    }

    async mount(volumeId) {
        const token = window.localStorage.getItem("token");

        return await this.client.post('/api/mount', { volume_id: volumeId}, {"X-Token": token});
    }

    async unmount(mountId) {
        const token = window.localStorage.getItem("token");

        return await this.client.post('/api/unmount', { mount_id: mountId}, {"X-Token": token});
    }

    async listfiles(mountId, path) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/mount/' + mountId + '/listfiles?path=' + path, {"X-Token": token});
    }

    async readfile(mountId, path, offset, length) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/mount/' + mountId + '/readfile?path=' + path + '&offset=' + offset + '&length=' + length,
                                {"X-Token": token}, false);
    }

    async setAppControlPolicy(policy) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/appcontrol/policy', {policy: policy}, {"X-Token": token});
    }

    async getAppControlPolicy() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/appcontrol/policy', {"X-Token": token});
    }

    async setNetFwPolicy(policy) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/netmonfw/policy', {policy: policy}, {"X-Token": token});
    }

    async getNetFwPolicy() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/netmonfw/policy', {"X-Token": token});
    }

    async getAgentEvents(query, since, until, offset, limit) {
        const token = window.localStorage.getItem("token");

        let uri = "/api/agent/events?query=" + encodeURIComponent(query);
        uri += "&since=" + encodeURIComponent(since);
        uri += "&until=" + encodeURIComponent(until);
        uri += "&offset=" + offset;
        uri += "&limit=" + limit;

        return await this.client.get(uri, {"X-Token": token});
    }

    async getAlerts(timestamp, offset, limit) {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/alerts?timestamp=' + timestamp + '&offset=' + offset + '&limit=' + limit, {"X-Token": token});
    }

    async getUnreadAlertsCounter() {
        const token = window.localStorage.getItem("token");
        return await this.client.get('/api/account/alerts/unread', {"X-Token": token});
    }

    async deleteAlert(alert_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/alerts/delete', {alert_id: alert_id}, {"X-Token": token});
    }

    async readAlert(alert_id) {
        const token = window.localStorage.getItem("token");
        return await this.client.post('/api/account/alerts/read', {alert_id: alert_id}, {"X-Token": token});
    }
}

var backend = null;

function getBackend() {
    if (backend != null)
        return backend;

    backend = new Backend();
    return backend;
}

export default getBackend