
import React from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";


import getBackend from "backend/backend";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Footer from "components/Footer";
import Header from "components/Header";
import {Helmet} from "react-helmet";

class VerificationForm extends React.Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
      this.onCodeChange = this.onCodeChange.bind(this);
      this.state = { error: null , code: null};
  }

  async onSubmit(e) {
      e.preventDefault();

      ReactGA.event({
        category: "user",
        action: "verification",
      });

      this.setState(state => ({
          error: null
      }), async () => {
          const result = await getBackend().verify(this.state.code);
          this.setState(state => ({
              error: result.error
          }));

          if (result.error == null) {
              window.location.href = Routes.Setup.path;
          }
      });
  }

  onCodeChange(e) {
      this.setState(state => ({
          code: e.target.value
      }));
  }

  render() {
      return (
        <main>
        <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
          <Container>
            <Row className="justify-content-center">
              <p className="text-center">
                <Card.Link as={Link} to={Routes.Presentation.path} className="d-flex align-items-center justify-content-center">
                  <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> {this.props.t('Back to homepage')}
                </Card.Link>
              </p>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="text-center text-md-center signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                  <h3>{this.props.t('Email verification')}</h3>
                  <p className="mb-4">{this.props.t("Please check your email address that you provided when creating your account. We have sent you an email with a verification code.")}</p>
                  <Form onSubmit={this.onSubmit}>
                    <div className="mb-4">
                      <Form.Label htmlFor="email">{this.props.t('Enter the verification code that can be found in the email from support@cydanix.com.')}</Form.Label>
                      <InputGroup id="email">
                        <Form.Control required autoFocus type="text" placeholder={this.props.t('Verification code')} onChange={this.onCodeChange}/>
                      </InputGroup>
                    </div>
                    <div className="d-grid">
                      <Button variant="gray-800" type="submit">
                        {this.props.t('Verify')}
                      </Button>
                      {this.state.error != null &&
                        <Alert variant="danger">{this.props.t('Something went wrong')}: {this.state.error}</Alert>
                      }
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>

      );
  }
}

export default () => {
  const [t, i18n] = useTranslation('common');

  return (
    <>
    <Helmet>
      <title>Cydanix Account Verification cydanix.com</title>
      <meta name="description" content="Cydanix account verification" />
    </Helmet>
    <Header/>
    <VerificationForm t={t}/>
    <Footer/>
    </>
);
};