
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { LuArchiveRestore } from 'react-icons/lu';

import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import {timestampToString, capitalizeFirstLetter, SwalWithBootstrapButtons, showError, showMessage} from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Image, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

const BackupsTable = (props) => {
  const { backups = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = backups.filter(u => u.isSelected).length === 0;

  const selectBackup = (id) => {
    props.selectBackup && props.selectBackup(id);
  };

  const selectAllbackups = () => {
    props.selectAllbackups && props.selectAllbackups();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteBackups();
  }

  const deleteBackups = (ids) => {
    props.deleteBackups && props.deleteBackups(ids)
  }

  const TableRow = (props) => {
    const { backup_id, status, timestamp, isSelected, progress, hostname, error_message } = props;
    const statusVariant = status === "complete" ? "success"
        : status === "pending" ? "purple"
          : status === "failed" ? "danger" : "primary";


    const onRestoreClick = async () => {
      await showMessage("Please select particular volume in the backup and click on the action button 'Restore'");
    }

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`computer-${backup_id}`} checked={isSelected} onChange={() => selectBackup(backup_id)} />
            <FormCheck.Label htmlFor={`computer-${backup_id}`} />
          </FormCheck>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center" as={Link} to={Routes.Backup.path + "/" + backup_id}>
            <div className="d-block">
              <span className="fw-normal">{backup_id}</span>
            </div>
          </Card.Link>
        </td>
        <td><span className="fw-normal">{hostname}</span></td>
        <td><span className="fw-normal">{timestampToString(timestamp)}</span></td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
        </td>
        <td><span className="fw-normal">{progress}</span></td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={onRestoreClick}>
                <LuArchiveRestore className="dropdown-icon text-normal me-2" />
                Restore
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteBackups([backup_id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="delete">Delete</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllbackups} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Id</th>
              <th className="border-bottom">Hostname</th>
              <th className="border-bottom">Creation date</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Progress</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {backups.map(u => <TableRow key={`backup-${u.backup_id}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default () => {
  const [computers, setComputers] = useState([]);
  const [backups, setBackups] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const selectedbackupsIds = backups.filter(u => u.isSelected).map(u => u.backup_id);
  const totalbackups = backups.length;
  const allSelected = selectedbackupsIds.length === totalbackups;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newbackups = backups.map(u => ({ ...u, show: u.backup_id.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setBackups(newbackups);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newbackups = backups.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setBackups(newbackups);
  };

  const selectAllbackups = () => {
    const newbackups = selectedbackupsIds.length === totalbackups ?
    backups.map(u => ({ ...u, isSelected: false })) :
    backups.map(u => ({ ...u, isSelected: true }));

    setBackups(newbackups);
  };

  const selectBackup = (id) => {
    const newbackups = backups.map(u => u.backup_id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setBackups(newbackups);
  };

  const deleteBackups = async (ids) => {
    const backupsToBeDeleted = ids ? ids : selectedbackupsIds;
    const backupsNr = backupsToBeDeleted.length;
    const textMessage = backupsNr === 1
      ? "Are you sure do you want to delete this backup?"
      : `Are you sure do you want to delete these ${backupsNr} backups?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      for (let i = 0; i < backupsToBeDeleted.length; i++) {
        await getBackend().deleteBackup(backupsToBeDeleted[i]);
      }

      const newbackups = backups.filter(f => !backupsToBeDeleted.includes(f.backup_id));
      const confirmMessage = backupsNr === 1 ? "The backup has been deleted." : "The backups have been deleted.";

      setBackups(newbackups);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  useEffect(() => {
    let canceled = false;

    const getBackups = async () => {
      const result = await getBackend().machines();
      if (canceled)
        return;

      if (result.error == null) {
        setComputers(result.response.machines.map(u => ({ ...u})));

        let localBackups = [];

        for (let i = 0; i < result.response.machines.length; i++) {
            const machine = result.response.machines[i];
            const result2 =  await getBackend().backups(machine.machine_id);
            if (canceled)
                return;

            if (result2.error == null) {
                localBackups = localBackups.concat(result2.response.backups.map(u => ({ ...u, isSelected: false, show: true,
                    hostname: machine.hostname,
                    status: (u.progress >= 100) ? "complete" : ((u.error_message !== "") ? "failed" : "pending")})));
            }
        }

        setBackups(localBackups);
      } else {
        if (!canceled)
          await showError(result.error);
      }
    };


    getBackups();

    return () => {
      canceled = true;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Cydanix Drive Backups cydanix.com</title>
        <meta name="description" content="Cydanix drive backups" />
      </Helmet>
      <SidebarPageHeader pageName="Drive Backups" pageTitle={"Drive Backups"} />

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search backups"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="complete">Complete</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
            </Form.Select>
          </Col>
        </Row>
      </div>

      <BackupsTable
        backups={backups.filter(u => u.show).sort((a, b) => {
          if (a.timestamp > b.timestamp)
            return -1;
          if (a.timestamp < b.timestamp)
            return 1;
          return 0;
        })
        }
        allSelected={allSelected}
        selectBackup={selectBackup}
        deleteBackups={deleteBackups}
        selectAllbackups={selectAllbackups}
      />
    </>
  );
};
