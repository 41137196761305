
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card, Container, Navbar, HashLink, Image, Nav } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import { Routes } from "routes";
import { Link } from 'react-router-dom';
import {SwalWithBootstrapButtons} from "util/util";

import {YoutubeIcon, TwitterIcon} from "components/BrandIcons";
import Subscribe from "account/Subscribe";
import CydanixLogo from "assets/img/cydanix.svg";

export default (props) => {
  const currentYear = moment().get("year");


  const [t, i18n] = useTranslation('common');

  return (
    <footer className="footer py-6 bg-primary text-white">
    <Container>
      <Row>
        <Col xs={6} md={3}>
          <Navbar.Brand as={Link} to="/" className="me-lg-3 mb-3 d-flex align-items-center">
            <Image src={CydanixLogo} />
            <span className="ms-2 brand-text">Cydanix</span>
          </Navbar.Brand>
        </Col>
        <Col xs={4} md={2} className="mb-5 mb-lg-0">
          <span className="h5">Cydanix</span>
          <ul className="links-vertical mt-2">
            <li><Nav.Link as={Link} to={Routes.Presentation.path}>{t('Home')}</Nav.Link></li>
            <li><Nav.Link as={Link} to={Routes.Business.path}>{t('Business')}</Nav.Link></li>
            <li><Nav.Link as={Link} to={Routes.Pricing.path}>{t('Pricing')}</Nav.Link></li>
            <li><Nav.Link as={Link} to={Routes.Blog.path}>{t('Blog')}</Nav.Link></li>
            <li><Nav.Link onClick={(e)=> { e.preventDefault(); window.location.href="mailto:info@cydanix.com";}}>{t('Contact Us')}</Nav.Link></li>
            <li><Nav.Link as={Link} to={Routes.TermsOfService.path}>{t('Terms of service')}</Nav.Link></li>
            <li><Nav.Link as={Link} to={Routes.PrivacyPolicy.path}>{t('Privacy policy')}</Nav.Link></li>
          </ul>
        </Col>
        <Col xs={6} md={4} className="mb-5 mb-lg-0">
          <Subscribe/>
        </Col>
        <Col xs={4} md={2} className="mb-5 mb-lg-0">
        <span className="h5">Follow Us</span>
          <ul className="links-vertical mt-2">
            <li><a href="https://twitter.com/cydanixcom"><TwitterIcon/> Twitter</a></li>
            <li><a href="https://www.youtube.com/@cydanix-dv7iw"><YoutubeIcon/> Youtube</a></li>
          </ul>
        </Col>
      </Row>
      <hr className="bg-gray-600 my-5" />
      <Row>
        <Col className="mb-md-2">
          <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
          <address>
            <a href="mailto:info@cydanix.com">info@cydanix.com</a><br />
            <a href="tel:+13072182214">+1 307-218-2214</a><br />
            30 N Gould St Suite R, Sheridan, WY 82801, United States<br />
            © Cydanix LLC <span className="current-year">{currentYear}</span> v{process.env.REACT_APP_VERSION}
          </address>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
  );
};
