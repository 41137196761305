
import React from "react";

import { Card, ListGroup } from 'react-bootstrap';

import getBackend from "backend/backend";

import { useTranslation } from "react-i18next";

import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

class RecoveryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { token: null};
    }

    async componentDidMount() {
        const accResult = await getBackend().account();
        const token = (accResult.error === null) ? accResult.response.token : null;


        this.setState(state => ({
            token: token
        }));
    }

    render() {
        return (
            <Card border="0" className="shadow mb-4 mb-xl-0">
            <Card.Body>
              <h5 className="mb-4">Volume {this.props.volume_id} recovery</h5>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>1. Download <a href="https://cydanix.com/cydanix_recovery.exe" style={{color: 'black'}}>cydanix_recovery.exe</a></ListGroup.Item>
                <ListGroup.Item>2. Find a disk ID(e.g.: \\.\D:, \\.\F:, or /dev/sda2) to restore to by diskpart or fdisk/lsblk</ListGroup.Item>
                <ListGroup.Item>3. Execute the following command in CMD as Administrator/root: <strong>cydanix_recovery.exe -server {window.location.hostname} -token {this.state.token} -volumeId {this.props.volume_id} -destination disk_ID</strong></ListGroup.Item>
                <ListGroup.Item>4. Wait for the recovery process to complete</ListGroup.Item>
                <ListGroup.Item>5. Mount or use disk. Restart your computer if necessary</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        )
    }
}

export default ({match}) => {
  const [t, i18n] = useTranslation('common');
  const volume_id = match.params.id;
  
  return (
    <>
      <Helmet>
        <title>Cydanix Recovery cydanix.com</title>
        <meta name="description" content="Cydanix recovery" />
      </Helmet>
      <SidebarPageHeader pageName="Recovery"/>

      <RecoveryPage t={t} volume_id={volume_id}/>

    </>
  );
};
