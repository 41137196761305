
import React from "react";
import { Col, Row, Card, Container } from 'react-bootstrap';

import { useTranslation } from "react-i18next";
import Footer from "components/Footer";
import Header from "components/Header";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
            <Header/>
            <Container>

            <Row>
                <Col>
                    <Card border="1" className="shadow mb-4 mb-xl-0 mt-2">
                    <Card.Body>
                    <header>
                        <h1>Cydanix LLC Privacy Policy</h1>
                    </header>
                    <article>
                    <p><strong>Last Updated:</strong> 03.06.2023</p>

                    <section id="introduction">
                        <h2>Introduction</h2>
                        <p>
                            At Cydanix LLC, accessible from https://cydanix.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Cydanix LLC Backup Software Service and how we use it.
                        </p>
                        <p>
                            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                        </p>
                    </section>

                    <section id="information-collection">
                        <h2>Information Collection And Use</h2>
                        <p>
                            We collect several different types of information for various purposes to provide and improve our Service to you.
                        </p>
                    </section>
                    
                    <section id="log-data">
                        <h2>Log Data</h2>
                        <p>
                            Whenever you use our Service, in a case of an error in the software we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the software when utilizing our Service, the time and date of your use of the Service, and other statistics.
                        </p>
                    </section>

                    <section id="cookies">
                        <h2>Cookies</h2>
                        <p>
                            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                        </p>
                        <p>
                            Our website uses these “cookies” to collect information and to improve our Service. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our Service.
                        </p>
                    </section>

                    <section id="service-providers">
                        <h2>Service Providers</h2>
                        <p>
                            We may employ third-party companies and individuals due to the following reasons:
                            <ul>
                                <li>To facilitate our Service;</li>
                                <li>To provide the Service on our behalf;</li>
                                <li>To perform Service-related services; or</li>
                                <li>To assist us in analyzing how our Service is used.</li>
                            </ul>
                        </p>
                        <p>
                            We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                        </p>
                    </section>

                    <section id="security">
                        <h2>Security</h2>
                        <p>
                            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                        </p>
                    </section>

                    <section id="links-to-other-sites">
                        <h2>Links to Other Sites</h2>
                        <p>
                            Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </p>
                    </section>

                    <section id="childrens-privacy">
                        <h2>Children's Privacy</h2>
                        <p>
                            Our Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                        </p>
                    </section>

                    <section id="changes-to-this-privacy-policy">
                        <h2>Changes to This Privacy Policy</h2>
                        <p>
                            We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                        </p>
                    </section>

                    <section id="contact-us">
                        <h2>Contact Us</h2>
                        <p>
                            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us by <a href="mailto:support@cydanix.com">email</a>.
                        </p>
                    </section>
                    </article>

                    </Card.Body>
                </Card>
                </Col>                
            </Row>
            <Footer/>

            </Container>
          </>

        )
    }
}

export default () => {

    const [t, i18n] = useTranslation('common');

    return (
        <PrivacyPolicy t={t}/>
    );
};