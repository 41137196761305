
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import {capitalizeFirstLetter, SwalWithBootstrapButtons} from "util/util";
import { XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Image, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";

const MountsTable = (props) => {
  const { mounts = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = mounts.filter(u => u.isSelected).length === 0;

  const selectMount = (id) => {
    props.selectMount && props.selectMount(id);
  };

  const selectAllmounts = () => {
    props.selectAllmounts && props.selectAllmounts();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "unmount") unmountMounts();
  }

  const unmountMounts = (ids) => {
    props.unmountMounts && props.unmountMounts(ids)
  }

  const TableRow = (props) => {
    const { mount_id, status, volume_id, mount_point, isSelected } = props;
    const statusVariant = status === "active" ? "success"
        : status === "pending" ? "purple"
          : status === "failed" ? "danger" : "primary";

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`computer-${mount_id}`} checked={isSelected} onChange={() => selectMount(mount_id)} />
            <FormCheck.Label htmlFor={`computer-${mount_id}`} />
          </FormCheck>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center">
            <div className="d-block">
              <span className="fw-normal">{mount_id}</span>
            </div>
          </Card.Link>
        </td>
        <td>
            <Card.Link className="d-flex align-items-center" as={Link} to={Routes.Volume.path + "/" + volume_id}>
            <span className="fw-normal">{volume_id}</span>
            </Card.Link>
        </td>
        <td><span className="fw-normal">{mount_point}</span></td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
        </td>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Unmount</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => unmountMounts([mount_id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="unmount">Unmount</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllmounts} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">MountId</th>
              <th className="border-bottom">VolumeId</th>
              <th className="border-bottom">MountPoint</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {mounts.map(u => <TableRow key={`mount-${u.mount_id}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ({match}) => {
  const machine_id = match.params.id;

  const [computer, setComputer] = useState({});
  const [mounts, setMounts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const selectedmountsIds = mounts.filter(u => u.isSelected).map(u => u.mount_id);
  const totalmounts = mounts.length;
  const allSelected = selectedmountsIds.length === totalmounts;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newmounts = mounts.map(u => ({ ...u, show: u.mount_id.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setMounts(newmounts);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newmounts = mounts.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setMounts(newmounts);
  };

  const selectAllmounts = () => {
    const newmounts = selectedmountsIds.length === totalmounts ?
    mounts.map(u => ({ ...u, isSelected: false })) :
    mounts.map(u => ({ ...u, isSelected: true }));

    setMounts(newmounts);
  };

  const selectMount = (id) => {
    const newmounts = mounts.map(u => u.mount_id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setMounts(newmounts);
  };

  const unmountMounts = async (ids) => {
    const mountsToBeUnmounted = ids ? ids : selectedmountsIds;
    const mountsNr = mountsToBeUnmounted.length;
    const textMessage = mountsNr === 1
      ? "Are you sure do you want to unmount this mount?"
      : `Are you sure do you want to unmount these ${mountsNr} mounts?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm unmounting",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i++) {
        await getBackend().unmountMachineVolume(machine_id, ids[i]);
      }

      const newmounts = mounts.filter(f => !mountsToBeUnmounted.includes(f.mount_id));
      const confirmMessage = mountsNr === 1 ? "The mount has been unmounted." : "The mounts have been unmounted.";

      setMounts(newmounts);
      await SwalWithBootstrapButtons.fire('Unmounted', confirmMessage, 'success');
    }
  };

  useEffect(() => {
    let canceled = false;
    const getComputer = async () => {
      const result = await getBackend().machine(machine_id);
      if (canceled)
        return;

      if (result.error == null) {
          setComputer(result.response.machine);
      } else {
          window.location.href = Routes.NotFound.path;
      }
    };
    getComputer();
    return () => {
      canceled = true;
    }
  }, [machine_id]);

  useEffect(() => {
    let canceled = false;
    const getMounts = async () => {
      const result = await getBackend().mounts(machine_id);
      if (canceled)
        return;

      if (result.error == null) {
        setMounts(result.response.mounts.map(u => ({ ...u, isSelected: false, show: true})));
      }
    };
    getMounts();
    return () => {
      canceled = true;
    }
  }, [machine_id]);

  return (
    <>
      <SidebarPageHeader pageName="Computer" pageTitle={"Computer " + machine_id + " volume mounts"} />

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search mounts"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="failed">Failed</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                  <span className="visually-hidden">Toggle Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <MountsTable
        mounts={mounts.filter(u => u.show).sort((a, b) => {
          if (a.timestamp > b.timestamp)
            return -1;
          if (a.timestamp < b.timestamp)
            return 1;
          return 0;
        })
        }
        allSelected={allSelected}
        selectMount={selectMount}
        unmountMounts={unmountMounts}
        selectAllmounts={selectAllmounts}
      />
    </>
  );
};
