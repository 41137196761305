
import React, { useState, useEffect } from "react";

import { Card, Button, ButtonGroup } from 'react-bootstrap';
import { MyValueChart } from "components/Charts";


export const MyValueWidget = (props) => {
  const { title, value, getData, getDataField} = props;
  const [period, setPeriod] = useState("week");
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let canceled = false;
    const updateData = async () => {
      const result = await getData(period, getDataField);
      if (canceled)
        return;

      setLabels(result.labels);
      setData(result.data);
    };

    updateData();
    return () => {
      canceled = true;
    }
  }, [period, getData, getDataField]);

  return (
    <Card className="border-0 bg-secondary-alt shadow">
      <Card.Header className="d-sm-flex flex-row align-items-center border-0 flex-0">
        <div className="d-block mb-3 mb-sm-0">
          <h5 className="fs-5 fw-extrabold mb-2">
            {title}
          </h5>
          {value &&
          <h3 className="fs-3 fw-extrabold">
            {value}
          </h3>
          }
        </div>
        <ButtonGroup className="ms-auto">
          <Button
            variant="secondary"
            active={period === "day"}
            onClick={() => setPeriod("day")}
          >
            Day
          </Button>
          <Button
            variant="secondary"
            active={period === "week"}
            onClick={() => setPeriod("week")}
          >
            Week
          </Button>
          <Button
            variant="secondary"
            active={period === "month"}
            onClick={() => setPeriod("month")}
          >
            Month
          </Button>
          <Button
            variant="secondary"
            active={period === "year"}
            onClick={() => setPeriod("year")}
          >
            Year
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body className="p-2">
        <MyValueChart name={title} period={period} labels={labels} data={data} />
      </Card.Body>
    </Card>
  );
};