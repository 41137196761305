
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Routes } from "routes";

import Presentation from "pages/Presentation";
import Dashboard from "pages/Dashboard";

import Mounts from 'pages/Mounts';
import Volume from 'pages/Volume';
import Recovery from 'pages/Recovery';

import Backup from 'pages/Backup';
import Setup from 'pages/Setup';

import Settings from "account/Settings";
import Billing from "account/Billing";
import Signin from "account/Signin";
import Signup from "account/Signup";
import Notifications from "account/Notifications";
import NotFoundPage from "pages/NotFound";
import ServerError from "pages/ServerError";
import Blog from "pages/Blog";
import BlogArticle from './BlogArticle';

import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';

import Verification from 'account/Verification';
import ReactGA from "react-ga4";
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Pricing from './Pricing';
import Business from './Business';
import AppControl from './AppControl';
import Events from './Events';
import NetworkFirewall from './NetworkFirewall';
import Goodbye from './Goodbye';
import Alerts from './Alerts';
import Hosts from './Hosts';
import Host from './Host';
import Backups from './Backups';

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  }

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true
  }

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Sidebar
          contracted={contractSidebar}
          onMouseEnter={toggleMouseOver}
          onMouseLeave={toggleMouseOver}
        />

        <main className="content">
          <Topbar toggleContracted={toggleContracted} />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};


const PageSwitch = () => {

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return (
    <Switch>
      <Route exact path={Routes.Presentation.path} component={Business} />
      <Route exact path={Routes.Signin.path} component={Signin} />
      <Route exact path={Routes.Signup.path} component={Signup} />
      <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
      <Route exact path={Routes.ServerError.path} component={ServerError} />
      <Route exact path={Routes.Verification.path} component={Verification} />
      <Route exact path={Routes.Blog.path} component={Blog} />
      <Route exact path={Routes.Pricing.path} component={Pricing} />
      <Route exact path={Routes.Business.path} component={Business} />

      <Route exact path={Routes.Blog.path + "/:id"} component={BlogArticle} />
      <Route exact path={Routes.PrivacyPolicy.path} component={PrivacyPolicy} />
      <Route exact path={Routes.TermsOfService.path} component={TermsOfService} />
      <Route exact path={Routes.Goodbye.path} component={Goodbye} />

      {/* pages */}
      <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />

      <RouteWithSidebar exact path={Routes.Backups.path} component={Backups} />
      <RouteWithSidebar exact path={Routes.Hosts.path} component={Hosts} />

      <RouteWithSidebar exact path={Routes.AppControl.path} component={AppControl} />
      <RouteWithSidebar exact path={Routes.NetFw.path} component={NetworkFirewall} />
      <RouteWithSidebar path={Routes.Events.path + "/:query?"} component={Events} />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />

      <RouteWithSidebar path={Routes.Host.path + "/:id"} component={Host} />

      <RouteWithSidebar path={Routes.Mounts.path + "/:id"} component={Mounts} />

      <RouteWithSidebar path={Routes.Backup.path + "/:id"} component={Backup} />
      <RouteWithSidebar path={Routes.Volume.path + "/:id"} component={Volume} />
      <RouteWithSidebar path={Routes.Recovery.path + "/:id"} component={Recovery} />

      <RouteWithSidebar exact path={Routes.Setup.path} component={Setup} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
      <RouteWithSidebar exact path={Routes.Billing.path} component={Billing} />
      <RouteWithSidebar exact path={Routes.Notifications.path} component={Notifications} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );

}

export default () => (
  <PageSwitch/>
);
