
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { LuArchiveRestore, LuFolder, LuFileText} from 'react-icons/lu';
import { HiDownload } from 'react-icons/hi';

import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import { showError, showMessage} from "util/util";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import { Nav, Card, Table, FormCheck } from 'react-bootstrap';

import getBackend from "backend/backend";
import { saveAs } from 'file-saver';
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";
import Spinner from 'react-bootstrap/Spinner';

const capitalizeFirstLetter = (string) => (
  string[0].toUpperCase() + string.slice(1)
);

const getFirstLetterOfEachWord = (text) => (
  text.match(/\b\w/g).join('')
);

const FilesTable = (props) => {
  const { files = [], allSelected, path } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = files.filter(u => u.isSelected).length === 0;

  const selectFile = (id) => {
    props.selectFile && props.selectFile(id);
  };

  const selectAllFiles = () => {
    props.selectAllFiles && props.selectAllFiles();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteFiles();
  }

  const deleteFiles = (ids) => {
    props.deleteFiles && props.deleteFiles(ids)
  }

  const onClickFile = async (name, directory, size) => {
    props.onClickFile && props.onClickFile(name, directory, size)
  }

  const movePathUp = () => {
    props.movePathUp && props.movePathUp();
  }

  const getMounting = () => {
    if (!props.getMounting)
      return false
    return props.getMounting();
  }

  const onRestoreClick = async () => {
    await showMessage("Restore feature is still in development and will be available soon. Please use volume views/mounts instead.");
  }

  const TableRow = (props) => {
    const { index, name, size, directory, isSelected } = props;

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`file-${index}`} checked={isSelected} onChange={() => selectFile(index)} />
            <FormCheck.Label htmlFor={`file-${index}`} />
          </FormCheck>
        </td>
        <td>
        <Card.Link onClick={() => onClickFile(name, directory, size)}>
          {directory
            ? <LuFolder/>
            : <LuFileText/>
          }
          </Card.Link>
        </td>
        <td>
          <Card.Link className="d-flex align-items-center" onClick={() => onClickFile(name, directory, size)}>
            <div className="d-block">
              <span className="fw-normal">{name}</span>
            </div>
          </Card.Link>
        </td>
        <td><span className="fw-normal">{directory ? "":size}</span></td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => onClickFile(name, directory, size)}>
                <HiDownload className="dropdown-icon text-gray-400 me-2" />
                Download
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={onRestoreClick}>
                <LuArchiveRestore className="dropdown-icon text-normal me-2" />
                Restore
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="pause">Download</option>
            <option value="resume">Restore</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
          <Button variant="primary" size="sm" className="ms-3" as={Link} to={Routes.Recovery.path + "/" + props.volume_id}>
            Recover
          </Button>

          {path !== "" &&
            <Button variant="primary" size="sm" className="ms-3" onClick={movePathUp}>
              Move Up
            </Button>
          }


        </div>
        <div>
        {getMounting() &&
          <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
        }
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllFiles} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom"></th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Size</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {files.map((u, index) => <TableRow key={`file-${index}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

const filesData = []

export default ({match}) => {
  const volume_id = match.params.id;

  const [mountId, setMount] = useState("");
  const [files, setFiles] = useState(filesData.map(u => ({ ...u, isSelected: false, show: true, status: "active"})));
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [path, setPath] = useState("");
  const [mounting, setMounting] = useState(true);

  const selectedFilesIds = files.filter(u => u.isSelected).map(u => u.machine_id);
  const totalFiles = files.length;
  const allSelected = selectedFilesIds.length === totalFiles;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newFiles = files.map(u => ({ ...u, show: u.name.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setFiles(newFiles);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newFiles = files.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setFiles(newFiles);
  };

  const selectAllFiles = () => {
    const newFiles = selectedFilesIds.length === totalFiles ?
    files.map(u => ({ ...u, isSelected: false })) :
    files.map(u => ({ ...u, isSelected: true }));

    setFiles(newFiles);
  };

  const selectFile = (id) => {
    const newFiles = files.map(u => u.machine_id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setFiles(newFiles);
  };

  const onClickFile = async (name, directory, size) => {
    if (directory) {
      setMounting(true);
      setPath(path + "/" + name);
    } else {
      if (size > 128*1024*1024) {
        await showError("File " + path + "/" + name + " size is too big " + size);
        return;
      }

      var parts = [];
      var offset = 0;
      while (offset < size) {
          const left = size - offset;
          const length = (left > 65536) ? 65536 : left;
          const result = await getBackend().readfile(mountId, path + "/" + name, offset, length);
          if (result.error != null) {
              await showError(result.error);
              return;
          }
          const data = atob(result.response.data);

          const bytes = new Array(data.length);
          for (let i = 0; i < data.length; i++) {
              bytes[i] = data.charCodeAt(i);
          }

          offset += data.length;
          parts.push(new Uint8Array(bytes));
      }
      saveAs(new Blob(parts), name);
    }
  }

  const deleteFiles = async (ids) => {
    const FilesToBeDeleted = ids ? ids : selectedFilesIds;
    const FilesNr = FilesToBeDeleted.length;
    const textMessage = FilesNr === 1
      ? "Are you sure do you want to delete this computer?"
      : `Are you sure do you want to delete these ${FilesNr} files?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newFiles = files.filter(f => !FilesToBeDeleted.includes(f.machine_id));
      const confirmMessage = FilesNr === 1 ? "The computer has been deleted." : "The files have been deleted.";

      setFiles(newFiles);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const movePathUp = () => {
    if (path !== "") {
      const pos = path.lastIndexOf("/");
      if (pos >= 0) {
          setMounting(true);
          setPath(path.slice(0, pos));
      }
    }
  }

  const getMounting = () => {
    return mounting;
  }

  useEffect(() => {
    let canceled = false;

    const getMount = async () => {
      if (mountId !== "") {
        return;
      }

      const result = await getBackend().mount(volume_id);
      if (result.error == null) {
        if (canceled) {
          await getBackend().unmount(result.response.mount_id);
          return;
        }
        setMount(result.response.mount_id);
      } else {
        if (!canceled)
          await showError(result.error);
      }
    };

    getMount();

    return async () => {
      canceled = true;
      if (mountId !== "") {
        await getBackend().unmount(mountId);
      }
    };
  }, [mountId, volume_id]);

  useEffect(() => {
    let canceled = false;

    const getFiles = async () => {
      if (mountId === "") {
        return;
      }

      const result = await getBackend().listfiles(mountId, path);
      if (result.error == null) {
        if (!canceled) {
          setFiles(result.response.files.map(u => ({ ...u, isSelected: false, show: true, status: "active"})));
          setMounting(false);
        }
      } else {
        if (!canceled)
          await showError(result.error);
      }
    }

    getFiles();

    return async () => {
      canceled = true;
    };
  }, [mountId, path]);

  return (
    <>
      <Helmet>
        <title>Cydanix Volume cydanix.com</title>
        <meta name="description" content="Cydanix volume" />
      </Helmet>
      <SidebarPageHeader pageName="Volume" pageTitle={path ? "Volume " + volume_id + " files, path " + path : "Volume " + volume_id + " files"} />

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search files"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="pending">Pending</option>
              <option value="suspended">Suspended</option>
            </Form.Select>
          </Col>
        </Row>
      </div>

      <FilesTable
        files={files.filter(u => u.show)}
        allSelected={allSelected}
        selectFile={selectFile}
        deleteFiles={deleteFiles}
        selectAllFiles={selectAllFiles}
        onClickFile={onClickFile}
        movePathUp={movePathUp}
        path={path}
        volume_id={volume_id}
        getMounting={getMounting}
      />
    </>
  );
};
