
// =========================================================
// * Volt Pro React Dashboard
// =========================================================

// * Product Page: https://themesberg.com/product/dashboard/volt-pro-react
// * Copyright 2021 Themesberg (https://www.themesberg.com)
// * License Information (https://themesberg.com/licensing)

// * Designed and coded by https://themesberg.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "leaflet/dist/leaflet.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_ru from "./translations/ru/common.json";
import common_en from "./translations/en/common.json";
import ReactGA from "react-ga4";

import { GoogleOAuthProvider } from '@react-oauth/google';
import getBackend from 'backend/backend';
import {Helmet} from "react-helmet";

const detectLang = () => {
  if (window.location.hostname.indexOf('.ru') >= 0)
    return 'ru';
  else
    return 'en';
}


ReactGA.initialize("G-R3KF77JR12");

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: detectLang(),                              // language to use
  fallbackLng: 'en',
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      ru: {
          common: common_ru
      },
  },
});

class AuthTokenRefresher extends React.Component {

  async renewToken() {
    await getBackend().renewToken();
  }

  async componentDidMount() {
    setInterval(this.renewToken, 2*60*1000);
  }

  render() {
      return (
          <>
          </>
      )
  }
}


ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Cydanix Cybersecurity - Cydanix cydanix.com</title>
      <meta name="description" content="Cydanix application" />
    </Helmet>
    <GoogleOAuthProvider clientId="599226032068-c7avbgmtm5o748ir7vg3mkps8cpfro6t.apps.googleusercontent.com">
      <AuthTokenRefresher/>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <ScrollToTop />
            <HomePage />
        </BrowserRouter>
      </I18nextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
