import React, { useState, useEffect } from "react";
import { Col, Row, Container } from 'react-bootstrap';

import {MyValueWidget} from "components/Widgets";
import getBackend from "backend/backend";
import {timestampToString} from "util/util";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

export default () => {
  const [usage, setUsage] = useState({});
  const [usageStats, setUsageStats] = useState([]);
  const [diskUsageData, setDiskUsageData] = useState([]);
  const [diskUsageLabels, setDiskUsageLabels] = useState([]);

  const getDiskSpaceUsageField = (stats) => {
    return stats.disk_usage;
  }

  const getBackupCountField = (stats) => {
    return stats.backup_count;
  }

  const getMachineCountField = (stats) => {
    return stats.machine_count;
  }

  const getBackupErrorsField = (stats) => {
    return stats.backup_fail_count;
  }

  const getData = async (period, getDataField) => {
    let delta = 24 * 3600 * 1000;
    switch (period) {
      case "day":
        delta = 24 * 3600 * 1000;
        break;
      case "week":
        delta = 7 * 24 * 3600 * 1000;
        break;
      case "month":
        delta = 31 * 24 * 3600 * 1000;
        break;
      case "year":
        delta = 365 * 24 * 3600 * 1000;
        break;
      default:
        delta = 24 * 3600 * 1000;
        break;
    }

    const now = Date.now();
    const steps = 7;
    var data = [];
    var labels = [];

    var timestamps = [];
    for (let i = 0; i < steps; i++) {
      timestamps.push(1000 * (now - delta + i * Math.trunc(delta/steps)));
    }

    const goodTimestamp = (result, timestamp, delta) => {
      if (result > timestamp) {
        if (result < timestamp + delta/2)
          return true;
      }
      return false;
    }

    for (let i = 0; i < timestamps.length; i++) {
      const result = await getBackend().getAccountUsageStats(timestamps[i], 0, 1);
      if (result.error == null) {
        if (result.response.usage_stats.length > 0) {
          if (goodTimestamp(result.response.usage_stats[0].timestamp, timestamps[i], 1000*Math.trunc(delta/steps))) {
            data.push(getDataField(result.response.usage_stats[0]));
            labels.push(timestampToString(result.response.usage_stats[0].timestamp));
          } else {
            data.push(0);
            labels.push(timestampToString(timestamps[i]));
          }
        }
      }
    }

    return {labels: labels, data : data}
  }

  useEffect(() => {
    let canceled = false;
    const getAccountUsage = async () => {
      const result = await getBackend().getAccountUsage();
      if (canceled)
        return;

      if (result.error == null) {
        setUsage(result.response);
      }
    };

    getAccountUsage();
    return () => {
      canceled = true;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Cydanix Dashboard cydanix.com</title>
        <meta name="description" content="Cydanix dashboard" />
      </Helmet>
      <Container fluid>
        <Row>
            <Col><SidebarPageHeader pageName="Dashboard"/></Col>
        </Row>

        <Row className="justify-content-lg-center">
          <Col xs={12} className="mb-4">
            <MyValueWidget
              title="Cloud storage usage"
              value={`${usage.usage} MB`}
              getData = {getData}
              getDataField = {getDiskSpaceUsageField}
            />
          </Col>
        </Row>
        <Row className="justify-content-lg-center">
          <Col xs={12} className="mb-4">
            <MyValueWidget
              title="Backup count"
              getData = {getData}
              getDataField = {getBackupCountField}
            />
          </Col>
        </Row>
        <Row className="justify-content-lg-center">
          <Col xs={12} className="mb-4">
            <MyValueWidget
              title="Computers count"
              getData = {getData}
              getDataField = {getMachineCountField}
            />
          </Col>
        </Row>
        <Row className="justify-content-lg-center">
          <Col xs={12} className="mb-4">
            <MyValueWidget
              title="Backup errors count"
              getData = {getData}
              getDataField = {getBackupErrorsField}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
