
import React from "react";
import { Col, Row, Card, Container } from 'react-bootstrap';

import { useTranslation } from "react-i18next";
import Footer from "components/HomeFooter";
import Header from "components/Header";
import { Routes } from "routes";
import ReactGA from "react-ga4";

import BlogData from "./../assets/data/BlogData.json";

import BlogImage1 from "../assets/img/blog/Embracing-Digital-Safety-The-Cool-Side-of-Personal-Computer-Backups.webp"
import BlogImage2 from "../assets/img/blog/Evaluating-the-Limitations-of-Native-Windows-Backup-Solutions.webp"
import BlogImage3 from "../assets/img/blog/The-Imperative-of-Comprehensive-Data-Backup-in-Enterprise-Environments.webp"
import BlogImage4 from "../assets/img/blog/The-Imperative-ofComputer-Data-Backup-Safeguarding-Your-Digital-Assetsl.jpg"
import BlogImage5 from "../assets/img/blog/The-Superiority-of-Cloud-Storage-Backup-Over-Local-Disks-A-Comprehensive-Analysis.webp"
import {Helmet} from "react-helmet";

export function TitleToUri(title) {
    return encodeURI(title.toLowerCase().replaceAll(" ", "-"))
}

export const BlogImage = (props) => {
    switch (props.title) {
    case "Embracing Digital Safety: The Cool Side of Personal Computer Backups":
        return <img src={BlogImage1} alt=""/>;
    case "Evaluating the Limitations of Native Windows Backup Solutions":
        return <img src={BlogImage2} alt=""/>;
    case "The Imperative of Comprehensive Data Backup in Enterprise Environments":
        return <img src={BlogImage3} alt=""/>;
    case "The Imperative of Computer Data Backup: Safeguarding Your Digital Assets":
        return <img src={BlogImage4} alt=""/>;
    case "The Superiority of Cloud Storage Backup Over Local Disks: A Comprehensive Analysis":
        return <img src={BlogImage5} alt=""/>;
    default:
        return <img alt=""/>;
    }
}

class BlogArticle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        //read cached "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cydanix"
        const data = BlogData;
        for (let i = 0; i < data.items.length; i++) {
            const item = data.items[i];
            if (TitleToUri(item.title) === this.props.article_id) {
                ReactGA.event({
                    category: "blog",
                    action: "blog-article-" + this.props.article_id,
                });
        
                this.setState(state => ({title: item.title, content: item.content, thumbnail: item.thumbnail}));
                return;
            }
        }
        window.location.href = Routes.NotFound.path;
    }

    render() {
        return (
            <>
            <Helmet>
                <title>Cydanix Blog Article cydanix.com</title>
                <meta name="description" content="Cydanix blog article" />
            </Helmet>
            <Header/>
            <Container>

            <Row>
                <Col>
                    <Card border="1" className="shadow mb-4 mb-xl-0 mt-2">
                    <Card.Body>
                    <div dangerouslySetInnerHTML={{ __html:this.state.content}}>
                    </div>
                    </Card.Body>
                </Card>
                </Col>                
            </Row>
            </Container>
            <Footer/>

          </>

        )
    }
}

export default ({match}) => {
    const article_id = match.params.id;

    const [t, i18n] = useTranslation('common');

    return (
        <BlogArticle t={t} article_id={article_id}/>
    );
};