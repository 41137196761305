
import React, { useState } from "react";
import { MenuAlt1Icon } from "@heroicons/react/solid";
import { Nav, Button, Navbar, Container} from 'react-bootstrap';

import Account from "account/Account";

export default (props) => {

  const toggleContracted = () => props.toggleContracted && props.toggleContracted();

  return (
    <Navbar expand variant="dark" className="navbar-top navbar-dashboard ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
          </div>
          <Nav className="align-items-center">
            <Account/>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
