import React, {useState} from "react";
import moment from "moment-timezone";
import { Col, Row, Card, Image, Button, Container, Navbar, Nav, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Routes } from "routes";

import DashboardImg from "assets/img/dashboard.png";
import BackupsImg from "assets/img/backups.png";
import MountedVolumeImg from "assets/img/mounted_volume.png";
import VolumeFilesImg from "assets/img/volume_files.png";
import SettingsImg from "assets/img/settings.png";
import AgentImg from "assets/img/agent.png";
import AppControlImg from "assets/img/appcontrol.png";
import NetworkFwImg from "assets/img/network_fw.png";
import CyberThreatImg from "assets/img/cyber_threat.png";
import BackupViewerImg from "assets/img/backup_viewer.png";

import {YoutubeIcon, TwitterIcon} from "components/BrandIcons";
import {useTranslation} from "react-i18next";
import Subscribe from "account/Subscribe";
import {PricingPresentation} from "account/Billing";
import { ConsoleGate } from "../components/ConsoleGate";
import Header from "components/Header";

import CydanixLogo from "assets/img/cydanix.svg";
import {Helmet} from "react-helmet";
import Footer from "components/HomeFooter";
import ImageViewer from "components/ImageViewer";

export default () => {
  const currentYear = moment().get("year");

  const [t, i18n] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>Cybersecurity - Cydanix cydanix.com</title>
        <meta name="description" content="Secure and reliable cloud backup for Windows endpoints. Effortlessly back up your data with our easy-to-use, automated solutions. Enjoy peace of mind with our advanced encryption and 24/7 support. Ideal for personal and business use. Start safeguarding your files today!"/>
      </Helmet>
      <Header/>
      <section className="section-header pt-3 pb-lg-3 bg-primary text-white overflow-hidden" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1 className="fw-bolder text-secondary">
                Cydanix Cybersecurity
              </h1>
              <p className="text-muted fw-light mb-5 h5">Securely Backup and Access Your Data Anytime, Anywhere, While Stopping Cyber Threats in Their Tracks!</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-md bg-soft pt-lg-3" id="features">
        <Container>
          <h2 className="border-bottom border-light mb-5 pb-3 text-center">{t('Key features of our solution')}</h2>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h3>{t('Cyber threat behavior detection')}</h3>
              <p className="mb-3 lead fw-bold">
                {t('Proactive defense for tomorrow\'s threats')}
              </p>
              <p className="mb-4">
                {t('Cyber threat behavior detection is essential for businesses as it provides a proactive approach to identifying and mitigating sophisticated cyber-attacks. Unlike traditional methods that rely on known signatures, behavior detection analyzes patterns and activities to uncover anomalies and potential threats. This capability is crucial in today\'s landscape of advanced persistent threats (APTs) and zero-day vulnerabilities, which can easily bypass conventional defenses.')}
              </p>
            </Col>
            <Col lg={6}>
              <ImageViewer src={CyberThreatImg} alt="Network firewall" thumbnail />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h3>{t('Data protection')}</h3>
              <p className="mb-3 lead fw-bold">
                {t('Protect business data from loss due to ransomware or disk failures')}
              </p>
              <p className="mb-4">
                {t('Data loss can have a significant impact on an enterprise\'s operations. By ensuring that all data is regularly backed up, businesses can minimize downtime and continue to operate even in the face of data loss. Avoid succumbing to ransom demands; with our system, you can effortlessly restore your data anytime.')}
              </p>
              <Button variant="primary" href="https://www.youtube.com/watch?v=eXXBcaddF_w" target="_blank">
                Learn More
              </Button>
            </Col>
            <Col lg={6}>
              <ImageViewer src={BackupViewerImg} alt="Data protection" thumbnail />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h3>{t('Application control')}</h3>
              <p className="mb-3 lead fw-bold">
                {t('Block unwanted cyber threats')}
              </p>
              <p className="mb-4">
                {t('Application control is a security measure designed to protect organizational resources from malware, ransomware, and other cyber threats that can infiltrate networks through unauthorized applications. By controlling which applications can run, organizations can significantly reduce their attack surface and the likelihood of a successful cyber attack.')}
              </p>
              <Button variant="primary" href="https://medium.com/@cydanix/how-cydanix-application-control-works-1a20a03af1d9" target="_blank">
                Learn More
              </Button>
            </Col>
            <Col lg={6}>
              <ImageViewer src={AppControlImg} alt="Application control" thumbnail />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h3>{t('Network firewall')}</h3>
              <p className="mb-3 lead fw-bold">
                {t('Block harmful network actions on endpoints')}
              </p>
              <p className="mb-4">
                {t('Network firewall on endpoints is a security measure designed to monitor and control the incoming and outgoing network traffic based on predetermined security rules. It serves as a barrier between a trusted internal network and untrusted external networks, such as the internet, effectively deciding which traffic is allowed to pass through to the endpoint devices, such as laptops, desktops, and mobile devices.')}
              </p>
              <Button variant="primary" href="https://medium.com/@cydanix/how-cydanix-network-firewall-works-16fee9a3be9d" target="_blank">
                Learn More
              </Button>
            </Col>
            <Col lg={6}>
              <ImageViewer src={NetworkFwImg} alt="Network firewall" thumbnail />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
    </>
  );
};
