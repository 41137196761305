
import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "routes";
import { useTranslation } from "react-i18next";
import Footer from "components/Footer";
import Header from "components/Header";
import {Helmet} from "react-helmet";

export default () => {
  const [t, i18n] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>Cydanix Unsubscription cydanix.com</title>
        <meta name="description" content="Cydanix not found" />
      </Helmet>
      <Header/>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <h1 className="mt-5">
                  {t('Unsubscription was successful')}
                </h1>
                <p className="lead my-4">
                  {t('Thank you for considering Cydanix!')}
                </p>
                <Button as={Link} variant="gray-800" className="d-inline-flex align-items-center justify-content-center mb-4" to={Routes.Presentation.path}>
                  <ChevronLeftIcon className="icon icon-xs me-2" />
                  {t('Go back home')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
    </>
  );
};
