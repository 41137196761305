
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, ArchiveIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import {timestampToString, capitalizeFirstLetter, SwalWithBootstrapButtons, showError} from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

const AlertsTable = (props) => {
  const { alerts = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = alerts.filter(u => u.isSelected).length === 0;

  const selectAlert = (id) => {
    props.selectAlert && props.selectAlert(id);
  };

  const selectAllAlerts = () => {
    props.selectAllAlerts && props.selectAllAlerts();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteAlerts();
    if (bulkOption === "read") readAlerts();
  }

  const deleteAlerts = (ids) => {
    props.deleteAlerts && props.deleteAlerts(ids)
  }

  const readAlerts = (ids) => {
    props.readAlerts && props.readAlerts(ids)
  }

  const AlertTableRow = (props) => {
    const { alert } = props;
    const status = alert.severity === "low" ? "primary"
      : alert.severity === "medium" ? "warning"
          : alert.severity === "high" ? "danger" : "primary";

    const getAlertDetails = (alert) => {
      switch (alert.type) {
      case "process_injection":
        return "process_injection";
      case "process_hollowing":
        return "process_hollowing";
      case "sid_elevation":
        return "sid_elevation"
      case "integrity_elevation":
        return "integrity_elevation"
      case "malicious_driver_load":
        return "malicious_driver_load"
      case "vulnerable_driver_load":
        return "vulnerable_driver_load"
      default:
        return "invalid";
      }
    }

    const getEventsUrl = (alert) => {
      let query = "";

      for (let i = 0; i < alert.events.length; i++) {
        const event = alert.events[i];

        query += ((i > 0) ? " OR " : "") + "event_id: " + event.header.event_id;
      }

      return "/events/" + encodeURIComponent(query);
    }


    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`alert-${alert.id}`} checked={alert.isSelected} onChange={() => selectAlert(alert.id)} />
            <FormCheck.Label htmlFor={`alert-${alert.id}`} />
          </FormCheck>
        </td>

        <td><span className="fw-normal">{timestampToString(alert.timestamp)}</span></td>

        <td>
          <span className={`fw-normal text-${status}`}>
            {capitalizeFirstLetter(alert.severity)}
          </span>
        </td>

        <td><span className="fw-normal">{alert.hostname}</span></td>

        <td>
          <span className="fw-normal">{getAlertDetails(alert)}</span>&nbsp;
          <Button variant="link" href={getEventsUrl(alert)}>Show events ({alert.events.length})</Button>
        </td>

        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
            <Dropdown.Item className="d-flex align-items-center" onClick={() => readAlerts([alert.id])}>
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" />
                Mark as read
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteAlerts([alert.id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="read">Mark as read</option>
            <option value="delete">Delete</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllAlerts} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Timestamp</th>
              <th className="border-bottom">Severity</th>
              <th className="border-bottom">Hostname</th>
              <th className="border-bottom">Details</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {alerts.map(u => <AlertTableRow key={`alert-${u.id}`} alert={u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default () => {

  const [alerts, setAlerts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [updateSeqNo, setUpdateSeqNo] = useState(0);

  const selectedAlertsIds = alerts.filter(u => u.isSelected).map(u => u.id);
  const totalAlerts = alerts.length;
  const allSelected = selectedAlertsIds.length === totalAlerts;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newAlerts = alerts.map(u => ({ ...u, show: u.details.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setAlerts(newAlerts);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newAlerts = alerts.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setAlerts(newAlerts);
  };

  const selectAllAlerts = () => {
    const newAlerts = selectedAlertsIds.length === totalAlerts ?
    alerts.map(u => ({ ...u, isSelected: false })) :
    alerts.map(u => ({ ...u, isSelected: true }));

    setAlerts(newAlerts);
  };

  const selectAlert = (id) => {
    const newAlerts = alerts.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setAlerts(newAlerts);
  };

  const deleteAlerts = async (ids) => {
    const alertsToBeDeleted = ids ? ids : selectedAlertsIds;
    const textMessage = alertsToBeDeleted.length === 1
      ? "Are you sure do you want to delete this alert?"
      : `Are you sure do you want to delete these ${alertsToBeDeleted.length} alerts?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      for (let i = 0; i < alertsToBeDeleted.length; i++) {
        await getBackend().deleteAlert(alertsToBeDeleted[i]);
      }

      const newAlerts = alerts.filter(f => !alertsToBeDeleted.includes(f.id));
      const confirmMessage = alertsToBeDeleted.length === 1 ? "The alert has been deleted." : "The alerts have been deleted.";

      setAlerts(newAlerts);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const readAlerts = async (ids) => {
    const selectedIds = ids ? ids : selectedAlertsIds;

    for (let i = 0; i < selectedIds.length; i++) {
      await getBackend().readAlert(selectedIds[i]);
    }

    setUpdateSeqNo(updateSeqNo + 1);
  }

  useEffect(() => {
    let canceled = false;

    const getAlerts = async () => {
      let alerts = [];
      const start_ts = 0; //1000*(Date.now()-7*24*3600*1000);
      let offset = 0;

      for (;;) {
        const result = await getBackend().getAlerts(start_ts, offset, 200);
        if (canceled)
          return;

        if (result.error === null) {
          if (result.response.alerts === null || result.response.alerts.length === 0)
            break;

          alerts.push(...result.response.alerts);
          offset += result.response.alerts.length;
        } else {
          await showError(result.error);
          break;
        }
      }

      setAlerts(alerts.map(u => ({ ...u, isSelected: false, show: true, status: (u.read) ? "read": "unread"})));
    };

    getAlerts();
    return () => {
      canceled = true;
    }
  }, [updateSeqNo]);

  return (
    <>
      <Helmet>
        <title>Cydanix Security Alerts cydanix.com</title>
        <meta name="description" content="Cydanix security alerts" />
      </Helmet>

      <SidebarPageHeader pageName="Security Alerts"/>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search alerts"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="unread">Unread</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                  <span className="visually-hidden">Toggle Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <AlertsTable
        alerts={alerts.filter(u => u.show).sort((a, b) => {
          if (a.timestamp > b.timestamp)
            return -1;
          if (a.timestamp < b.timestamp)
            return 1;
          return 0;
        })}
        allSelected={allSelected}
        selectAlert={selectAlert}
        deleteAlerts={deleteAlerts}
        readAlerts={readAlerts}
        selectAllAlerts={selectAllAlerts}
      />
    </>
  );
};
