
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import { Routes } from "routes";
import { Link } from 'react-router-dom';
import {SwalWithBootstrapButtons} from "util/util";

export default (props) => {
  const currentYear = moment().get("year");
  const [t, i18n] = useTranslation('common');

  return (
    <div>
      <footer className="bg-primary text-white rounded shadow p-5 mb-4 mt-4">
        <Row>
          <Col>
            <p className="mb-0 text-center text-lg-start">
              <span>© Cydanix LLC {` ${currentYear}`} v{process.env.REACT_APP_VERSION}</span>
            </p>
          </Col>
          <Col xs={8}>
            <ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href={Routes.Presentation.path}>
                  {t('Home')}
                </Card.Link>
              </li>

              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href={Routes.Business.path}>
                  {t('Business')}
                </Card.Link>
              </li>

              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href={Routes.Pricing.path}>
                  {t('Pricing')}
                </Card.Link>
              </li>

              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href={Routes.Blog.path}>
                  {t('Blog')}
                </Card.Link>
              </li>

              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="mailto:support@cydanix.com">
                  {t('Contact Us')}
                </Card.Link>
              </li>

            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};
