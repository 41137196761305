
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, ArchiveIcon, CheckIcon, CogIcon, PauseIcon, PlayIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown, Modal, Alert } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import {timestampToStringWithMillis, capitalizeFirstLetter, SwalWithBootstrapButtons, showError} from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Image, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";
import { showMessage } from "util/util";
import validator from 'validator';
import DateRangePicker from "components/DateRangePicker";
import moment from "moment";
import { sprintf } from "sprintf-js";

const pathBaseName = (path) => {
  const i = path.lastIndexOf('\\');

  if (i >= 0)
    return path.substring(i+1);

  return path;
}

const getEventProcessDetails = (event) => {
  return pathBaseName((event.pid === 4) ? "System" : event.image);
}

const getEventDetails = (event) => {
  switch (event.type) {
  case "appcontrol_block":
    return getEventProcessDetails(event) + " " + pathBaseName(event.target_image);
  case "net_connection":
    let direction = "->";
    if (event.direction === "inbound") {
      direction = "<-";
    }
    return getEventProcessDetails(event) + " " + event.protocol + " " + event.local_address + ":" + event.local_port + " " + direction + " " + event.remote_address + ":" + event.remote_port;
  case "hash_guard_file_blocked":
    return getEventProcessDetails(event) + " " + pathBaseName(event.path) + " " + event.hash;
  case "process_exit":
    return getEventProcessDetails(event);
  case "process_create":
  case "thread_create":
  case "thread_queue_apc":
  case "thread_set_context":
  case "thread_resume":
  case "process_write_virt_memory":
  case "process_map_view_of_section":
  case "process_unmap_view_of_section":
  case "process_allocate_virt_memory":
    return getEventProcessDetails(event) + " -> " + pathBaseName(event.target_image);
  case "reg_key_create":
    return getEventProcessDetails(event) + " " + pathBaseName(event.key_name);
  case "reg_key_set_value":
    return getEventProcessDetails(event) + " " + pathBaseName(event.key_name) + " " + pathBaseName(event.value_name);
  case "file_create":
    return getEventProcessDetails(event) + " " + pathBaseName(event.file_name);
  case "file_write":
    return getEventProcessDetails(event) + " " + pathBaseName(event.file_name);
  case "image_load":
    return getEventProcessDetails(event) + " " + pathBaseName(event.target_image);
  case "dns_query":
    return getEventProcessDetails(event) + " " + event.query_name;
  default:
    return "";
  }
}

const getHostname = (event) => {
  if (event.domain && event.domain !== "") {
    return event.domain + "\\" + event.hostname;
  }

  return event.hostname;
}

const EventTableRow = (props) => {
  const {id, event} = props;

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails(!showDetails);

  return (
    <Card style={{"border-radius": "0"}}>
      <Card.Body>
      <Row>
      <Col md={3}>
            <span className="fw-normal">{timestampToStringWithMillis(1000*event.timestamp)}</span>
      </Col>
      <Col md={2}>
            <span className="fw-normal">{event.machine_id}</span>
      </Col>
      <Col md={2}>
            <span className="fw-normal">{getHostname(event)}</span>
      </Col>
      <Col md={2}>
            <span className="fw-normal">{event.type}</span>
      </Col>
      <Col md={3}>
          <span className="fw-normal">{getEventDetails(event)}</span>&nbsp;
          <Button variant="link" onClick={toggleDetails}>
            {showDetails ? 'hide details' : '...'}
          </Button>
      </Col>
      </Row>
      {showDetails &&
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <pre>{JSON.stringify(event, null, 2)}</pre>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      }
      </Card.Body>
    </Card>
  );
};

const EventsTable = (props) => {
  const { events = [] } = props;

  return (
        <>
              <Card style={{"border-radius": "0"}}>
              <Card.Body>
              <Row>
                <Col md={3}>
                  <span className="fw-bold">Timestamp</span>
                </Col>
                <Col md={2}>
                <span className="fw-bold">MachineID</span>
                </Col>
                <Col md={2}>
                <span className="fw-bold">Hostname</span>
                </Col>
                <Col md={2}>
                <span className="fw-bold">Type</span>
                </Col>
                <Col md={3}>
                <span className="fw-bold">Details</span>
                </Col>
              </Row>
              </Card.Body>
              </Card>
            {events.map(u => <EventTableRow key={`event-${u.id}`} event={u} />)}

      </>
  );
};

export default ({match}) => {
    const query = match.params.query;
    const decodedQuery = (typeof query === "string" && query.trim().length > 0) ? decodeURIComponent(query.trim()) : "";
    const [events, setEvents] = useState([]);
    const [searchValue, setSearchValue] = useState(decodedQuery);
    const [searchValueFinalized, setSearchValueFinalized] = useState(decodedQuery);
    const [updateSeqNo, setUpdateSeqNo] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [searchLimit, setSearchLimit] = useState(50);
    const [totalHits, setTotalHits] = useState(0);

    const momentToString = (ts) => {
      const date = ts.format("YYYY-MM-DD");
      const time = ts.format("HH:mm:ss");
      const millis = ts.milliseconds();

      return date + "T" + time + "." + sprintf("%03d", millis) + "Z";
    }

    const [since, setSince] = useState(momentToString(moment.utc().subtract("30", "days")));
    const [until, setUntil] = useState(momentToString(moment.utc()));

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    const runSearch = () => {
        setSearchValueFinalized(searchValue);
        setUpdateSeqNo(updateSeqNo + 1);
    }

    const onSearchKeyPress = (e) => {
        if (e.charCode === 13) {
            runSearch();
        }
    }

    useEffect(() => {
        let canceled = false;

        const getEvents = async () => {
            const result = await getBackend().getAgentEvents(searchValueFinalized, since, until, searchOffset, searchLimit);
            if (canceled)
                return;

            let localEvents = [];
            if (result.error !== null) {
                if (!canceled) {
                    setEvents(localEvents);
                    setTotalHits(0);
                    await showError(result.error);
                }
                return;
            } else {

                for (let i = 0; i < result.response.events.length; i++) {
                    localEvents.push({...result.response.events[i], id: i});
                }

                localEvents.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : ((a.timestamp > b.timestamp) ? -1 : 0));
                setTotalHits(result.response.total_hits);
            }

            setEvents(localEvents);
        };

        getEvents();
        return () => {
            canceled = true;
        }
    }, [searchValueFinalized, updateSeqNo, since, until, searchOffset, searchLimit]);

  return (
    <>
      <Helmet>
        <title>Cydanix Event Explorer cydanix.com</title>
        <meta name="description" content="Cydanix event explorer" />
      </Helmet>
      <SidebarPageHeader pageName="Event Explorer"/>

      <Row className="mb-3">
        <Col>
        <Form.Control
                    type="text"
                    placeholder="Event search query"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onSearchKeyPress}
                />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <DateRangePicker since={since} until={until} setSince={setSince} setUntil={setUntil} />
        </Col>
      </Row>
      <Row className="mb-3">
          <Col md={2}>
                <Form.Label>results per page</Form.Label>
          </Col>
          <Col md={2}>
                <Form.Select onChange={(e) => {setSearchLimit(parseInt(e.target.value));}}>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </Form.Select>
            </Col>
      </Row>
      <Row className="mb-3">
        <Col>
        <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {e.preventDefault(); runSearch();}}>
                Search<span className="icon icon-xs ms-3" />
        </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <span className="fw-bold">Showing {events.length} events out of {totalHits}, page {searchOffset/searchLimit}:</span>
        </Col>
      </Row>
      <EventsTable
        events={events}
      />
      <Row className="mb-3">
        <Col>
        <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {e.preventDefault(); setSearchOffset((searchOffset < searchLimit) ? 0 : (searchOffset-searchLimit)); runSearch();}}>
                Back<span className="icon icon-xs ms-3" />
            </Button>
        <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {e.preventDefault(); setSearchOffset(searchOffset+searchLimit); runSearch();}}>
                Next<span className="icon icon-xs ms-3" />
            </Button>
        </Col>
      </Row>


    </>
  );
};
