
import React from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";

import getBackend from "backend/backend";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { GoogleLogin } from '@react-oauth/google';
import Footer from "components/Footer";
import Header from "components/Header";
import {DividerOr} from "util/util";
import {Helmet} from "react-helmet";

async function signin_begin(auth_type, email, password) {
  const result = await getBackend().signin(auth_type, email, (email === "demo@cydanix.com") ? "1q2w3eQAZ" : password);
  return result;
}

async function signin_end(result) {
  if (result.error == null) {
    const result2 = await getBackend().account();

    if (result2.error != null) {
      window.location.href = Routes.Presentation.path;
    } else {
      if (result2.error == null && result2.response.machine_count === 0)
        window.location.href = Routes.Setup.path;
      else
        window.location.href = Routes.Dashboard.path;
    }
  }
}

export async function signin_ex(auth_type, email, password) {
  const result = await signin_begin(auth_type, email, password);
  await signin_end(result);
}

export class SigninFormBase extends React.Component {

  async signin(auth_type, email, password) {
    ReactGA.event({
      category: "user",
      action: "login",
    });

    ReactGA.event({
      category: "user",
      action: "login-" + auth_type,
    });

    this.setState(state => ({
        error: null
    }), async () => {
        const result = await signin_begin(auth_type, email, password);
        this.setState(state => ({
            error: result.error
        }));

        await signin_end(result);
    });
  }
}

class SigninForm extends SigninFormBase {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
      this.onEmailChange = this.onEmailChange.bind(this);
      this.onPasswordChange = this.onPasswordChange.bind(this);
      this.state = { error: null , email: null, password: null};
  }

  async onSubmit(e) {
    e.preventDefault();

    this.signin("email", this.state.email, this.state.password);
  }

  onPasswordChange(e) {
      this.setState(state => ({
          password: e.target.value
      }));
  }

  onEmailChange(e) {
      this.setState(state => ({
          email: e.target.value
      }));
  }

  render() {
      return (
        <main>
        <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Presentation.path} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> {this.props.t('Back to homepage')}
              </Card.Link>
            </p>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{this.props.t('Sign in to your account')}</h3>
                  </div>
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      this.signin("google", null, credentialResponse.credential);
                    }}
                    onError={() => {
                      this.setState(state => ({
                        error: "something went wrong"
                      }));
                    }}
                  />
                  <DividerOr/>
                  <Form className="mt-4" onSubmit={this.onSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>{this.props.t('Email')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <MailIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required type="email" placeholder="example@company.com" onChange={this.onEmailChange}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>{this.props.t('Password')}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <LockClosedIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control required type="password" placeholder={this.props.t('password')} onChange={this.onPasswordChange}/>
                        </InputGroup>
                      </Form.Group>
                      {/*
                      <div className="d-flex justify-content-between align-items-top mb-4">
                        <Form.Check type="checkbox">
                          <FormCheck.Input id="defaultCheck5" className="me-2" />
                          <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">{this.props.t('Remember me')}</FormCheck.Label>
                        </Form.Check>
                        <Card.Link className="small text-end" as={Link} to={Routes.ForgotPassword.path}>{this.props.t('Lost password')}?</Card.Link>
                      </div>
                    */}
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="gray-800" type="submit">
                        {this.props.t('Sign in')}
                      </Button>
                      {this.state.error != null &&
                        <Alert variant="danger">{this.props.t('Something went wrong')}: {this.state.error}</Alert>
                      }
                    </div>
                  </Form>
                  {/*
                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">{this.props.t('or login with')}</span>
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                      <FacebookIcon size="xs" color="currentColor" />
                    </Button>
                    <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                      <TwitterIcon size="xs" color="currentColor" />
                    </Button>
                    <Button variant="outline-gray-500" className="btn-icon-only btn-pill">
                      <GithubIcon size="xs" color="currentColor" />
                    </Button>
                  </div>
                  */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      {this.props.t('Don\'t have an account?')}
                      <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      &nbsp;{this.props.t('Sign up')}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
  


      );
  }
}

export default () => {
  const [t, i18n] = useTranslation('common');

  return (
    <>
    <Helmet>
      <title>Cydanix Account Signin cydanix.com</title>
      <meta name="description" content="Cydanix account signin" />
    </Helmet>
    <Header/>
    <SigninForm t={t}/>
    <Footer/>
    </>
  );
};
