
import React from "react";

import { Col, Row, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Footer from "components/HomeFooter";
import Header from "components/Header";
import { TitleToUri } from "./BlogArticle";
import BlogData from "./../assets/data/BlogData.json";
import {Helmet} from "react-helmet";

class BlogRoot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {articles: []};
    }

    async componentDidMount() {
        ReactGA.event({
            category: "blog",
            action: "blog-root",
        });

        //read cached "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cydanix"
        const data = BlogData;
        let articles = []
        for (let i = 0; i < data.items.length; i++) {
            const item = data.items[i];

            articles.push({link: "/blog/" + TitleToUri(item.title),
                title: item.title, pub_date: item.pubDate, thumbnail: item.thumbnail});
        }
        this.setState(state => ({articles: articles}));
    }

    render() {
        return (
            <>
            <Helmet>
                <title>Cydanix Blog cydanix.com</title>
                <meta name="description" content="Cydanix blog" />
            </Helmet>
            <Header/>
            <div className="mb-5 mb-lg-0 pt-lg-5">
            <Container>
                <h1 className="mb-4">Cydanix Blog</h1>
                {this.state.articles.map((article, index) => (

                <Row key={index}>
                    <Col>
                        <Card border="0" className="shadow mb-4 mb-xl-0 mt-2" as={Link} to={article.link}>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <p>{article.pub_date}</p>
                                    <h4>{article.title}</h4>
                                    </Col>
                                    <Col>
                                    <img src={article.thumbnail} alt=""/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                ))}

          </Container>
          </div>
          <Footer/>

          </>

        )
    }
}

export default () => {
    const [t, i18n] = useTranslation('common');

    return (
        <BlogRoot t={t}/>
    );
};