
import getBackend from "backend/backend";
import React, { useState, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import ReactGA from "react-ga4";
import { Col, Row, Card, Button, Container, ListGroup, Form, Modal, Alert} from 'react-bootstrap';
import { Routes } from "routes";
import { Link } from 'react-router-dom';
import validator from 'validator';

import { CheckIcon } from "@heroicons/react/solid";
import SidebarPageHeader from "components/SidebarPageHeader";
import { showError, showMessage, showConfirmation } from "util/util";
import {Helmet} from "react-helmet";

const planToName = {
  "data_backup_free" : "Free",
  "data_backup_professional_v2" : "Professional",
  "data_backup_enterprise": "Enterprise"
};

const planToPrice = {
  "data_backup_free" : "0$ per month",
  "data_backup_professional_v2" : "4$ per month for each TB of storage",
  "data_backup_enterprise": "Custom pricing"
};

const planToPurpose = {
  "data_backup_free" : "Great for personal use",
  "data_backup_professional_v2" : "Best for professionals",
  "data_backup_enterprise": "Ideal for business enterprises"
};

const planProperties = {
  "data_backup_free" : ["No credit card required", "Backup cloud storage: up to .5TB", "Personal S3 storage for use as backup storage"],
  "data_backup_professional_v2": ["Usage-based billing", "Backup cloud storage: up to 40TB", "Application control", "Network firewall", "Cyber threat behavior detection"],
  "data_backup_enterprise" : ["On-premises deployment", "Cloud deployment", "24/7 support"]
};

const canUpgradePlan = (plan) => {
  switch (plan) {
  case "data_backup_free":
    return "data_backup_professional_v2";
  default:
    return "";
  }
}

const canDowngradePlan = (plan) => {
  switch (plan) {
  case "data_backup_professional_v2":
    return "data_backup_free";
  default:
    return "";
  }
}

const BillingSlogan = "Plans & Pricing";

const ContactSalesDialog = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompany("");
    setReason("");
    setMessage("");
    setError("");
    setShow(false);
    props.setShow(false);
  }

  const onSubmit = async (e) => {
    ReactGA.event({
      category: "user",
      action: "contact_sales",
    });

    if (!firstName) {
      setError("First name not specified");
      return;
    }

    if (!lastName) {
      setError("Last name not specified");
      return;
    }

    if (!email) {
      setError("Email name not specified");
      return;
    }

    if (!validator.isEmail(email)) {
      setError("Email is not valid");
      return;
    }

    if (!company) {
      setError("Company not specified");
      return;
    }

    if (!reason) {
      setError("Reason for contacting not specified");
      return;
    }

    const request = {first_name: firstName, last_name: lastName, email: email, company: company, reason: reason, message: message};

    const result = await getBackend().contactSales(request);
    if (result.error !== null) {
      setError(result.error);
      return;
    } else {
      handleClose();
      await showMessage("Thank you!", "Your request has been sent successfully. Our managers will contact you shortly.");
    }
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact sales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First name"
                required
                onChange={(e) => {setFirstName(e.target.value);}}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last name"
                required
                onChange={(e) => {setLastName(e.target.value);}}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Business email</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@company.com"
                required
                onChange={(e) => {setEmail(e.target.value);}}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company name"
                required
                onChange={(e) => {setCompany(e.target.value);}}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Reason for contacting</Form.Label>
              <Form.Select onChange={(e) => {setReason(e.target.value);}}>
                <option>Select one</option>
                <option value="evaluate">I want to evaluate Cydanix for my organization</option>
                <option value="learn_more">I'd like to learn more about Cydanix features</option>
                <option value="issue">I have an issue with the product or payments</option>
                <option value="help">I need help using the platform</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Any specific questions? (optional)</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={(e) => {setMessage(e.target.value);}}/>
            </Form.Group>
            <Button variant="primary" onClick={onSubmit}>
              Submit
            </Button>
            {error !== "" &&
            <Alert variant="danger">
              {error}
            </Alert>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const BasePlanEntry = (props) => {
  return (
      <>
      <Card border="light" className="rounded-md shadow-sm mb-3 px-2" style={{ height: '25rem' }}>
        <Card.Header className="bg-white border-light p-4">
          <h4 className="mb-3 text-black">{planToName[props.plan]}</h4>
          <div className="d-flex mb-3"><strong>{planToPrice[props.plan]}</strong></div>
          <p className="fw-normal font-small mb-0">
            {planToPurpose[props.plan]}
          </p>
        </Card.Header>
        <Card.Body className="py-4">
          {props.children}

          <ListGroup className="simple-list">
            <ListGroup.Item className="fw-normal border-0">
              {planProperties[props.plan].map((object, i) => {return <div key={i}><CheckIcon className="icon icon-xxs text-success me-2"/>{object}<br/></div>})}
            </ListGroup.Item>
          </ListGroup>

        </Card.Body>
        <Card.Footer className="bg-white border-0 pt-0 px-4 pb-4">
        </Card.Footer>
      </Card>
      </>
  )
}

export const EnterprisePlanEntry = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <BasePlanEntry plan="data_backup_enterprise">
        <Button ariant="primary" className="w-100 text-dark rounded animate-up-2" onClick={(e) => {e.preventDefault(); setShow(true)}}>
          Contact sales<span className="icon icon-xs ms-3" />
        </Button>
      </BasePlanEntry>
      <ContactSalesDialog show={show} setShow={setShow}/>
    </>
  )
}

const onChangeSubscription = async (updateCallback, newSubPriceLookupKey) => {
  ReactGA.event({
    category: "subscription",
    action: "change",
  });

  let result = await showConfirmation("Change subscription", "Are you going to change current subscription?");
  if (result.isConfirmed) {
    const result = await getBackend().updateSubscription(newSubPriceLookupKey);
    if (result.error == null) {
      await showMessage("Change subscription", "Please wait ~1 min to update subscription status and refresh the page");
      if (updateCallback)
        updateCallback();
    } else
      await showError(result.error);
  }
}

export const FreePlanEntry = (props) => {

  return (
    <>
      <BasePlanEntry plan="data_backup_free">
        {props.link &&
        <Button as={Link} variant="primary" to={props.link} className="w-100 text-dark rounded animate-up-2">
          Try now<span className="icon icon-xs ms-3" />
        </Button>
        }

        {props.subscription &&
          <>
          {props.subscription.price_lookup_key === "data_backup_free" ?
            <>
            <Button variant="primary" className="w-100 text-dark rounded animate-up-2" onClick={(e) => {e.preventDefault(); onChangeSubscription(props.updateCallback, "");}}>
              Cancel subscription<span className="icon icon-xs ms-3" />
            </Button>
            </>
            :
            <Button variant="primary" className="w-100 text-dark rounded animate-up-2" onClick={(e) => {e.preventDefault(); onChangeSubscription(props.updateCallback, "data_backup_free");}}>
              Select<span className="icon icon-xs ms-3" />
            </Button>
          }
          </>
        }

      </BasePlanEntry>
    </>
  )
}

export const ProfessionalPlanEntry = (props) => {
  return (
    <>
      <BasePlanEntry plan="data_backup_professional_v2">
        {props.link &&
        <Button as={Link} variant="primary" to={props.link} className="w-100 text-dark rounded animate-up-2">
          Try now<span className="icon icon-xs ms-3" />
        </Button>
        }

        {props.subscription &&
          <>
          {props.subscription.price_lookup_key === "data_backup_professional_v2" ?
            <>
            <Form action="/checkout-create-portal-session" method="POST">
              <input type="hidden" name="token" value={props.token}/>
              <Button variant="primary" className="w-100 text-dark rounded animate-up-2" type="submit">
                Manage subscription
              </Button>
            </Form>
            <Container style={{ height: '5px' }}></Container>
            <Button variant="primary" className="w-100 text-dark rounded animate-up-2" onClick={(e) => {e.preventDefault(); onChangeSubscription(props.updateCallback, "");}}>
              Cancel subscription<span className="icon icon-xs ms-3" />
            </Button>
            </>
            :
            <Form action="/checkout-create-session" method="POST">
              <input type="hidden" name="price_lookup_key" value="data_backup_professional_v2" />
              <input type="hidden" name="token" value={props.token}/>
              <Button variant="primary" className="w-100 text-dark rounded animate-up-2" type="submit">
                Buy<span className="icon icon-xs ms-3" />
              </Button>
            </Form>
          }
          </>
        }
      </BasePlanEntry>
    </>
  )
}

export const PricingPresentation = (props) => {
  return (
    <Container id="pricing">
      <Row className="justify-content-center mb-6">
        <Col xs={12} lg={9} className="text-center">
          <h2 className="display-3 fw-light mb-4">{BillingSlogan}</h2>
        </Col>
      </Row>
      <Row className="mb-4 mb-lg-5">
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <FreePlanEntry link={Routes.Signup.path}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <ProfessionalPlanEntry link={Routes.Signup.path}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <EnterprisePlanEntry/>
        </Col>
      </Row>
    </Container>
  )
}

const ManageBillingInfo = (props) => {

  const getSubscriptionStatus = (state, substate) => {
    if (substate === "cancel_at_period_end") {
      return state + " and will be effectively canceled at period end";
    }

    return state;
  }

  return (
    <>
      <SidebarPageHeader pageName="Billing" />
      <Row className="justify-content-center mb-6">
        <Col xs={12} lg={9} className="text-center">
          <h2 className="display-3 fw-light mb-4">{BillingSlogan}</h2>
        </Col>
      </Row>
      <Row className="mb-4 mb-lg-5">
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <FreePlanEntry token={props.token} updateCallback={props.updateCallback} subscription={props.subscription}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <ProfessionalPlanEntry token={props.token} updateCallback={props.updateCallback} subscription={props.subscription}/>
        </Col>
        <Col xs={12} lg={6} xl={4} className="mb-5">
          <EnterprisePlanEntry/>
        </Col>
      </Row>
    </>
  );
};


export default () => {
  let [token, setToken] = useState('');
  let [billingInfo, setBillingInfo] = useState({});
  let [updateSeqno, setUpdateSeqno] = useState(0);
  const location = useLocation();
  const history = useHistory();

  const updateCallback = () => {
    setUpdateSeqno(updateSeqno + 1);
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let canceled = false;

    setToken(window.localStorage.getItem("token"));

    ReactGA.event({
      category: "checkout",
      action: "show",
    });

    if (query.get("success")) {
      ReactGA.event({
        category: "checkout",
        action: "success",
      });

      let sessionId = query.get("session_id");

      const setAccountBillingSession = async (localSessionId) => {
        const result = await getBackend().setAccountBillingSession(localSessionId);
        if (result.error == null) {
          await showMessage("Subscription creation", "Please wait ~1 min to update subscription status and refresh the page");
        } else {
          await showError(result.error)
        }

        query.delete("success");
        query.delete("session_id");
        history.replace({
          search: query.toString(),
        });
      }

      if (!canceled)
        setAccountBillingSession(sessionId);
    }

    if (query.get('canceled')) {
      ReactGA.event({
        category: "checkout",
        action: "canceled",
      });
      query.delete("canceled");
      query.delete("session_id");
      history.replace({
        search: query.toString(),
      });
    }

    const getAccountBillingInfo = async () => {
      const result = await getBackend().getAccountBillingInfo();
      if (canceled)
        return;

      if (result.error == null) {
        setBillingInfo(result.response);
      } else {
        if (!canceled)
          await showError(result.error);
      }
    };

    getAccountBillingInfo();
    return () => {
      canceled = true;
    }

  }, [location.search, history, updateSeqno]);

  return (
    <>
      <Helmet>
        <title>Cydanix Account Billing cydanix.com</title>
        <meta name="description" content="Cydanix account billing" />
      </Helmet>
      {(billingInfo.subscription_price_lookup_key === "data_backup_free" || (billingInfo.customer_id !== "" && billingInfo.subscription_state !== "canceled"))
        ? <ManageBillingInfo updateCallback={updateCallback} token={token} subscription={{state: billingInfo.subscription_state, substate: billingInfo.subscription_substate, price_lookup_key: billingInfo.subscription_price_lookup_key}} />
        : <ManageBillingInfo updateCallback={updateCallback} token={token} subscription={{}} />
      }

    </>
  )
};
