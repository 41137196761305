
import React from "react";


import Footer from "components/Footer";
import Header from "components/Header";
import {Helmet} from "react-helmet";
import {PricingPresentation} from "account/Billing";

export default () => {

    return (
        <>

            <Helmet>
                <title>Cydanix Pricing cydanix.com</title>
                <meta name="description" content="Cydanix pricing and plans" />
            </Helmet>
            <Header/>
            <div className="mb-5 mb-lg-0 pt-lg-5">
            <PricingPresentation/>
            </div>
            <Footer/>
        </>
    );
};