import React, {useState} from "react";
import {Image, Modal } from 'react-bootstrap';

const ImageViewer = (props) => {
    const [showModal, setShowModal] = useState(false);
  
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
  
    return (
        <>
          <Image src={props.src} alt={props.alt} thumbnail onClick={handleModalShow}/>
          <Modal show={showModal} onHide={handleModalClose} size="xl">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Image src={props.src} alt={props.alt} style={{ width: '100%', height: '100%' }} />
            </Modal.Body>
          </Modal>
        </>
    )
}

export default ImageViewer;