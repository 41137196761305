
import React from "react";
import { Col, Row, Card, Container } from 'react-bootstrap';

import { useTranslation } from "react-i18next";
import Footer from "components/Footer";
import Header from "components/Header";

class TermsOfService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
            <Header/>
            <Container>

            <Row>
                <Col>
                    <Card border="1" className="shadow mb-4 mb-xl-0 mt-2">
                    <Card.Body>

                    <section id="terms-of-service">
                        <header>
                            <h1>Cydanix LLC Terms of Service</h1>
                        </header>
                        <article>
                            <p><strong>Last Updated:</strong> 03.06.2023</p>
                            
                            <section id="welcome">
                                <h2>Welcome to Cydanix LLC!</h2>
                                <p>We provide a backup software service that ensures your data is securely copied, stored, and available for recovery. These terms of service ("Terms") govern your use of the Cydanix LLC backup software and any related services provided by Cydanix LLC ("we", "us", or "our").</p>
                            </section>

                            <section id="acceptance-of-terms">
                                <h2>Acceptance of Terms</h2>
                                <p>By accessing or using our service, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of the terms, you are prohibited from using or accessing this service.</p>
                            </section>

                            <section id="modification-of-terms">
                                <h2>Modification of Terms</h2>
                                <p>We reserve the right, at our discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                            </section>

                            <section id="account-registration">
                                <h2>Account Registration</h2>
                                <p>You may be required to create an account to use our service. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                            </section>

                            <section id="privacy-policy">
                                <h2>Privacy Policy</h2>
                                <p>Your privacy is important to us. Please read our <a href="/privacy">Privacy Policy</a>, which describes how we collect, use, and disclose information that pertains to your privacy. When you access or use the service, you signify your agreement to the Privacy Policy as well.</p>
                            </section>

                            <section id="access-to-service">
                                <h2>Access to Service</h2>
                                <p>Subject to these Terms, we grant you a non-exclusive, limited, revocable, personal, non-transferable license to use the service. Except as expressly provided herein, you agree not to license, distribute, make derivative works, display, sell, or "frame" content from the service.</p>
                            </section>

                            <section id="user-conduct">
                                <h2>User Conduct</h2>
                                <p>You agree not to use the service to:</p>
                                <ul>
                                    <li>Violate any laws or regulations.</li>
                                    <li>Transmit any viruses, malware, or other types of malicious software.</li>
                                    <li>Infringe the intellectual property rights of others.</li>
                                    <li>Harvest, collect, or gather user data without their consent.</li>
                                </ul>
                            </section>

                            <section id="data-backup">
                                <h2>Data Backup</h2>
                                <p>While we perform regular backups, we do not guarantee there will be no loss or corruption of data. We recommend that you maintain a backup of all your data outside our service.</p>
                            </section>

                            <section id="service-availability">
                                <h2>Service Availability</h2>
                                <p>We do not guarantee that the service will be accessible at all times. In the event of service interruptions, we will strive to restore access as quickly as possible.</p>
                            </section>

                            <section id="fees-and-payment">
                                <h2>Fees and Payment</h2>
                                <p>If you purchase any services that we offer for a fee, you agree to Cydanix LLC storing your payment card information. You also agree to pay the applicable fees for the services (including, without limitation, periodic fees for premium accounts) as they become due plus all related taxes.</p>
                            </section>

                            <section id="termination">
                                <h2>Termination</h2>
                                <p>We may terminate or suspend your access to the service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
                            </section>

                            <section id="disclaimers">
                                <h2>Disclaimers</h2>
                                <p>The service is provided on an "AS IS" and "AS AVAILABLE" basis. We disclaim all warranties of any kind, whether express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement.</p>
                            </section>

                            <section id="limitation-of-liability">
                                <h2>Limitation of Liability</h2>
                                <p>To the fullest extent permitted by applicable law, in no event will Cydanix LLC, its affiliates, officers, directors, employees, agents, suppliers, or licensors be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.</p>
                            </section>

                            <section id="governing-law">
                                <h2>Governing Law</h2>
                                <p>These Terms shall be governed by and construed in accordance with the laws of United States, without regard to its conflict of law provisions.</p>
                            </section>

                            <section id="contact-us">
                                <h2>Contact Us</h2>
                                <p>If you have any questions about these Terms, please contact us by <a href="mailto:support@cydanix.com">email</a>.</p>
                            </section>

                        </article>
                    </section>


                    </Card.Body>
                </Card>
                </Col>                
            </Row>
            <Footer/>

            </Container>
          </>

        )
    }
}

export default () => {

    const [t, i18n] = useTranslation('common');

    return (
        <TermsOfService t={t}/>
    );
};