
import React from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";

import getBackend from "backend/backend";

import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { showMessage } from "util/util";
import { GoogleLogin } from '@react-oauth/google';
import { SigninFormBase } from "./Signin";
import Footer from "components/Footer";
import Header from "components/Header";
import {DividerOr} from "util/util";
import {Helmet} from "react-helmet";

class SignupForm extends SigninFormBase {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
      this.onEmailChange = this.onEmailChange.bind(this);
      this.onPasswordChange = this.onPasswordChange.bind(this);
      this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);

      this.state = { error: null , email: null, password: null, confirm_password: null};
  }

  async onSubmit(e) {
      e.preventDefault();

      ReactGA.event({
        category: "user",
        action: "sign_up",
      });

      if (this.state.password !== this.state.confirm_password) {
        this.setState(state => ({
          error: "passwords mismatch"
        }));
        return;
      }

      this.setState(state => ({
          error: null
      }), async () => {
          const result = await getBackend().signup(this.state.email, this.state.password);
          if (result.error == null) {
              window.location.href = Routes.Verification.path;
          } else {
            this.setState(state => ({
              error: result.error
            }));
          }
      });
  }

  onPasswordChange(e) {
      this.setState(state => ({
          password: e.target.value
      }));
  }

  onConfirmPasswordChange(e) {
    this.setState(state => ({
      confirm_password: e.target.value
    }));
  }

  onEmailChange(e) {
      this.setState(state => ({
          email: e.target.value
      }));
  }

  render() {
      return (
        <main>
        <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Presentation.path} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> {this.props.t('Back to homepage')}
              </Card.Link>
            </p>
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{this.props.t('Create your Cydanix account')}</h3>
                  </div>
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      this.signin("google", null, credentialResponse.credential);
                    }}
                    onError={() => {
                      this.setState(state => ({
                        error: "something went wrong"
                      }));
                    }}
                  />
                  <DividerOr/>
                  <Form className="mt-4" onSubmit={this.onSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>{this.props.t('Email')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <MailIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required type="email" placeholder="example@company.com" onChange={this.onEmailChange}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>{this.props.t('Password')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder={this.props.t('password')} onChange={this.onPasswordChange}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-4">
                      <Form.Label>{this.props.t('Re-enter password')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder={this.props.t('password')} onChange={this.onConfirmPasswordChange}/>
                      </InputGroup>
                    </Form.Group>

                    <div className="d-grid">
                      <Button variant="gray-800" type="submit">
                        {this.props.t('Create account')}
                      </Button>
                      {this.state.error != null &&
                        <Alert variant="danger">{this.props.t('Something went wrong')}: {this.state.error}</Alert>
                      }
                    </div>
                  </Form>
                  {/*
                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">or</span>
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                      <FacebookIcon size="xs" color="currentColor" />
                    </Button>
                    <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                      <TwitterIcon size="xs" color="currentColor" />
                    </Button>
                    <Button variant="outline-gray-500" className="btn-icon-only btn-pill">
                      <GithubIcon size="xs" color="currentColor" />
                    </Button>
                  </div>
                    */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      {this.props.t('Have an account?')}
                      <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      &nbsp;{this.props.t('Sign in')}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>

      );
  }
}

export default () => {
  const [t, i18n] = useTranslation('common');

  return (
    <>
    <Helmet>
      <title>Cydanix Account Signup cydanix.com</title>
      <meta name="description" content="Cydanix account signup" />
    </Helmet>
    <Header/>
    <SignupForm t={t}/>
    <Footer/>
    </>
  );
};
