
import React from "react";
import { Col, Row } from 'react-bootstrap';

import { Routes } from "routes";
import { Link } from 'react-router-dom';

import { showError } from "util/util";
import { Card, ListGroup } from 'react-bootstrap';

import getBackend from "backend/backend";

import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

class DownloadPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { token: null, version: null, hash: null};
        this.downloadFile = this.downloadFile.bind(this);
    }

    async componentDidMount() {
        const accResult = await getBackend().account();
        const token = (accResult.error === null) ? accResult.response.token : null;

        const agentVersionResult = await getBackend().getAgentVersion();
        const version = (agentVersionResult.error === null) ? agentVersionResult.response.version : null;
        const hash = (agentVersionResult.error === null) ? agentVersionResult.response.hash : null;

        this.setState(state => ({
            token: token,
            version: version,
            hash: hash
        }));
    }

    async downloadFile() {
        ReactGA.event({
          category: "user",
          action: "download_agent",
        });

        const result = await getBackend().getAgent();
        if (result.error == null) {
            saveAs(result.response, 'cydanix-agent.msi');
        } else {
          await showError(result.error);
        }
    }

    render() {
        return (
          <>
            <Row>
              <Col>
                <Card border="0" className="shadow mb-4">
                <Card.Body>
                  <h5 className="mb-4">Installation guide for Cydanix agent on Windows 10/11</h5>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>1. Download <a href="https://cydanix.com/cydanix_agent.msi" style={{color: 'black'}}>cydanix_agent.msi (version {this.state.version})</a> by clicking <a href="https://cydanix.com/cydanix_agent.msi" style={{color: 'black'}}>here</a></ListGroup.Item>
                    <ListGroup.Item>2. Start the installation by double clicking <strong>cydanix_agent.msi</strong> on the target host</ListGroup.Item>
                    <ListGroup.Item>3. Click Next</ListGroup.Item>
                    <ListGroup.Item>4. Enter <strong>{this.state.token}</strong> as the Installation Token</ListGroup.Item>
                    <ListGroup.Item>5. Enter <strong>{window.location.hostname}</strong> as the Server</ListGroup.Item>
                    <ListGroup.Item>6. Click Next to continue with the installation</ListGroup.Item>
                    <ListGroup.Item>7. Wait about 3 minutes for the installation to complete, then open the <Link to={Routes.Hosts.path} style={{color: 'black'}}>Hosts page</Link> and find the host by its hostname</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card border="1" className="shadow mb-4">
                  <Card.Body>
                  <h5 className="mb-4">Unattended installation</h5>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item>1. Launch CMD/Powershell as Administrator</ListGroup.Item>
                      <ListGroup.Item>2. Run the following command to install: <strong>msiexec /i https://cydanix.com/cydanix_agent.msi /qn SERVER={window.location.hostname} TOKEN={this.state.token}</strong></ListGroup.Item>
                      <ListGroup.Item>3. Wait about 3 minutes for the installation to complete, then open the <Link to={Routes.Hosts.path} style={{color: 'black'}}>Hosts page</Link> and find the host by its hostname</ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )
    }
}

export default () => {
  const [t, i18n] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>Cydanix Agent Setup cydanix.com</title>
        <meta name="description" content="Cydanix agent setup" />
      </Helmet>
      <SidebarPageHeader pageName="Add Host"/>

      <DownloadPage t={t}/>

    </>
  );
};
