
import React, { useState, useEffect } from "react";
import { AdjustmentsIcon, ArchiveIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import {timestampToString, capitalizeFirstLetter, SwalWithBootstrapButtons, showError} from "util/util";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";
import { Nav, Card, Table, Tooltip, FormCheck, OverlayTrigger } from 'react-bootstrap';

import getBackend from "backend/backend";
import SidebarPageHeader from "components/SidebarPageHeader";
import {Helmet} from "react-helmet";

const NotificationsTable = (props) => {
  const { notifications = [], allSelected } = props;
  const [bulkOption, setBulkOption] = useState(0);
  const disabledBulkMenu = notifications.filter(u => u.isSelected).length === 0;

  const selectNotification = (id) => {
    props.selectNotification && props.selectNotification(id);
  };

  const selectAllNotifications = () => {
    props.selectAllNotifications && props.selectAllNotifications();
  };

  const bulkActionChange = (e) => {
    const newOption = e.target.value;
    setBulkOption(newOption);
  }

  const applyBulkAction = () => {
    if (bulkOption === "delete") deleteNotifications();
    if (bulkOption === "read") readNotifications();
  }

  const deleteNotifications = (ids) => {
    props.deleteNotifications && props.deleteNotifications(ids)
  }

  const readNotifications = (ids) => {
    props.readNotifications && props.readNotifications(ids)
  }

  const TableRow = (props) => {
    const { id, severity, message, timestamp, isSelected, read } = props;
    const status = severity === "info" ? "success"
      : severity === "warning" ? "warning"
          : severity === "error" ? "danger" : "primary";

    return (
      <tr className="border-bottom">
        <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input id={`notification-${id}`} checked={isSelected} onChange={() => selectNotification(id)} />
            <FormCheck.Label htmlFor={`notification-${id}`} />
          </FormCheck>
        </td>

        <td>
          <span className={`fw-normal text-${status}`}>
            {capitalizeFirstLetter(severity)}
          </span>
        </td>

        {read
          ? <td><span className="fw-normal">{message}</span></td>
          : <td><span className="fw-bolder">{message}</span></td>
        }

        <td><span className="fw-normal">{timestampToString(timestamp)}</span></td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
            <Dropdown.Item className="d-flex align-items-center" onClick={() => readNotifications([id])}>
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" />
                Mark as read
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
            <Card.Link className="ms-2" onClick={() => deleteNotifications([id])}>
              <XCircleIcon className="icon icon-xs text-danger" />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="0" className="table-wrapper table-responsive shadow" style={{ minHeight: '600px' }}>
      <Card.Body>
        <div className="d-flex mb-3">
          <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
            <option value="bulk_action">Bulk Action</option>
            <option value="read">Mark as read</option>
            <option value="delete">Delete</option>
          </Form.Select>
          <Button variant="secondary" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
            Apply
          </Button>
        </div>
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <FormCheck type="checkbox" className="dashboard-check">
                  <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllNotifications} />
                  <FormCheck.Label htmlFor="userCheckAll" />
                </FormCheck>
              </th>
              <th className="border-bottom">Severity</th>
              <th className="border-bottom">Message</th>
              <th className="border-bottom">Timestamp</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {notifications.map(u => <TableRow key={`notification-${u.id}`} {...u} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default () => {

  const [notifications, setNotifications] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [updateSeqNo, setUpdateSeqNo] = useState(0);

  const selectedNotificationsIds = notifications.filter(u => u.isSelected).map(u => u.id);
  const totalNotifications = notifications.length;
  const allSelected = selectedNotificationsIds.length === totalNotifications;

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newNotifications = notifications.map(u => ({ ...u, show: u.message.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setNotifications(newNotifications);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newNotifications = notifications.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setNotifications(newNotifications);
  };

  const selectAllNotifications = () => {
    const newNotifications = selectedNotificationsIds.length === totalNotifications ?
    notifications.map(u => ({ ...u, isSelected: false })) :
    notifications.map(u => ({ ...u, isSelected: true }));

    setNotifications(newNotifications);
  };

  const selectNotification = (id) => {
    const newNotifications = notifications.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setNotifications(newNotifications);
  };

  const deleteNotifications = async (ids) => {
    const notificationsToBeDeleted = ids ? ids : selectedNotificationsIds;
    const textMessage = notificationsToBeDeleted.length === 1
      ? "Are you sure do you want to delete this notification?"
      : `Are you sure do you want to delete these ${notificationsToBeDeleted.length} notifications?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      for (let i = 0; i < notificationsToBeDeleted.length; i++) {
        await getBackend().deleteNotification(notificationsToBeDeleted[i]);
      }

      const newNotifications = notifications.filter(f => !notificationsToBeDeleted.includes(f.id));
      const confirmMessage = notificationsToBeDeleted.length === 1 ? "The notification has been deleted." : "The notifications have been deleted.";

      setNotifications(newNotifications);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const readNotifications = async (ids) => {
    const selectedIds = ids ? ids : selectedNotificationsIds;

    for (let i = 0; i < selectedIds.length; i++) {
      await getBackend().readNotification(selectedIds[i]);
    }

    setUpdateSeqNo(updateSeqNo + 1);
  }

  useEffect(() => {
    let canceled = false;

    const getNotifications = async () => {
      let notifications = [];
      const start_ts = 1000*(Date.now()-7*24*3600*1000);
      let offset = 0;

      for (;;) {
        const result = await getBackend().getNotifications(start_ts, offset, 200);
        if (canceled)
          return;

        if (result.error === null) {
          if (result.response.notifications === null || result.response.notifications.length === 0)
            break;

          notifications.push(...result.response.notifications);
          offset += result.response.notifications.length;
        } else {
          await showError(result.error);
          break;
        }
      }

      setNotifications(notifications.map(u => ({ ...u, isSelected: false, show: true, status: (u.read) ? "read": "unread"})));
    };

    getNotifications();
    return () => {
      canceled = true;
    }
  }, [updateSeqNo]);

  return (
    <>
      <Helmet>
        <title>Cydanix Account Notifications cydanix.com</title>
        <meta name="description" content="Cydanix account notifications" />
      </Helmet>

      <SidebarPageHeader pageName="Notifications"/>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search notifications"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="unread">Unread</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                  <span className="visually-hidden">Toggle Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <NotificationsTable
        notifications={notifications.filter(u => u.show).sort((a, b) => {
          if (a.timestamp > b.timestamp)
            return -1;
          if (a.timestamp < b.timestamp)
            return 1;
          return 0;
        })}
        allSelected={allSelected}
        selectNotification={selectNotification}
        deleteNotifications={deleteNotifications}
        readNotifications={readNotifications}
        selectAllNotifications={selectAllNotifications}
      />
    </>
  );
};
